import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import { useMainStore } from "@/store/main";
import enUS from "./locales/en-US/index";
import koKR from "./locales/ko-KR/index";
import jQuery from "jquery";
import PrimeVue from "primevue/config";
import VueApexCharts from "vue3-apexcharts";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { useAuthStore } from "./store/auth";

window.$ = jQuery;

const app = createApp(App);

app.component(VueQrcode.name, VueQrcode);

app.use(PrimeVue);
app.use(createPinia());
//저장되어 있던 언어 정보를 이용하여 언어를 설정
const mainStore = useMainStore();
type MessageSchema = typeof koKR;
const i18n = createI18n<[MessageSchema], "en-US" | "ko-KR">({
  legacy: false,
  locale: mainStore.language,
  messages: {
    "en-US": enUS,
    "ko-KR": koKR,
  },
});

app.use(router);

//페이지 다시 로드시에도 로그인이 되어 있으면 자동 로그아웃 타이머가 동작하도록 수정
const authStore = useAuthStore();
authStore.startLogoutTimer(router);

app.use(i18n);
app.use(VueApexCharts);
app.mount("#app");

export { i18n };
