import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { Router } from "vue-router";
import jwt_decode from "jwt-decode";
import { AuthToken, AccessToken, RefreshToken } from "@/types/user";
import { UserApi } from "@/api/userApi";

const loadAccessJwt = (): string => {
  const sValue: string | null = sessionStorage.getItem("access");
  if (sValue === null) {
    return "";
  }
  return sValue;
};

const loadRefreshJwt = (): string => {
  const sValue: string | null = sessionStorage.getItem("refresh");
  if (sValue === null) {
    return "";
  }
  return sValue;
};

const loadTimeZone = (): string => {
  const sValue: string | null = sessionStorage.getItem("time_zone");
  if (sValue === null) {
    return "Asia/Seoul";
  }
  return sValue;
};

const loadDateTimeFormat = (): string => {
  const sValue: string | null = sessionStorage.getItem("datetime_format");
  if (sValue === null) {
    return "yyyy-MM-dd";
  }
  return sValue;
};

export const useAuthStore = defineStore("auth", () => {
  let timerId: number | null = null;
  let router: Router | null = null;
  const accessJwt = ref(loadAccessJwt());
  const refreshJwt = ref(loadRefreshJwt());
  const time_zone = ref(loadTimeZone());
  const datetime_format = ref(loadDateTimeFormat());

  const saveAccessJwt = (sValue: string) => {
    accessJwt.value = sValue;
    sessionStorage.setItem("access", sValue);
  };

  const saveRefreshJwt = (sValue: string) => {
    refreshJwt.value = sValue;
    sessionStorage.setItem("refresh", sValue);
  };

  const saveTimeZone = (sValue: string) => {
    time_zone.value = sValue;
    sessionStorage.setItem("time_zone", sValue);
  };

  const saveDateTimeFormat = (sValue: string) => {
    datetime_format.value = sValue;
    sessionStorage.setItem("datetime_format", sValue);
  };

  // 로그인 토큰 정보들을 저장한다
  const setAuthToken = (at: AuthToken) => {
    saveAccessJwt(at.access_jwt);
    saveRefreshJwt(at.refresh_jwt);

    saveTimeZone(accessToken.value.time_zone);
    saveDateTimeFormat(accessToken.value.datetime_format);
  };

  // 로그인 토큰 정보를 삭제한다
  const clearAuthToken = async () => {
    saveAccessJwt("");
    saveRefreshJwt("");

    saveTimeZone("");
    saveDateTimeFormat("");

    stopLogoutTimer();
  };

  // Refresh 토큰을 이용해서 Access 토큰 정보를 갱신한다
  const refreshAuthToken = async (): Promise<string | null> => {
    const [status, data] = await UserApi.Refresh(
      accessJwt.value,
      refreshJwt.value
    );
    if (status === 200) {
      setAuthToken(data as AuthToken);
    } else {
      clearAuthToken();
      await requestLogout();
      router?.push({ name: "login" });
    }
    return accessJwt.value;
  };

  const requestLogout = async (): Promise<void> => {
    const [status, data] = await UserApi.Logout(refresh_token_id.value);
    if (status == 200) {
      console.log("successfully logout");
    } else {
      console.log("failed to logout: ", data);
    }
  };

  const startLogoutTimer = (r: Router) => {
    router = r;
    stopLogoutTimer();
    console.log("startLogoutTimer : ", auto_logout_timeout.value);
    if (auto_logout_timeout.value > 0) {
      timerId = window.setTimeout(() => {
        clearAuthToken();
      }, auto_logout_timeout.value);
      window.addEventListener("mousemove", resetLogoutTimer);
      window.addEventListener("keydown", resetLogoutTimer);
    }
  };

  const stopLogoutTimer = () => {
    if (timerId) {
      window.removeEventListener("mousemove", resetLogoutTimer);
      window.removeEventListener("keydown", resetLogoutTimer);
      clearTimeout(timerId);
      timerId = null;
    }
  };

  const resetLogoutTimer = () => {
    if (timerId) {
      clearTimeout(timerId);
    }

    if (auto_logout_timeout.value > 0) {
      if (router) {
        startLogoutTimer(router);
      }
    }
  };

  const accessToken = computed((): AccessToken => {
    if (accessJwt.value) {
      return jwt_decode<AccessToken>(accessJwt.value);
    }
    return {} as AccessToken;
  });

  const refreshToken = computed((): RefreshToken => {
    if (refreshJwt.value) {
      return jwt_decode<RefreshToken>(refreshJwt.value);
    }
    return {} as RefreshToken;
  });

  const id = computed((): string => {
    return accessToken.value.uid;
  });

  const email = computed(() => {
    return accessToken.value.email;
  });

  const name = computed(() => {
    return accessToken.value.name;
  });

  const role_id = computed(() => {
    return accessToken.value.role_id;
  });

  const role_name = computed(() => {
    return accessToken.value.role_name;
  });

  const auto_logout_timeout = computed(() => {
    return accessToken.value.auto_logout_timeout * 60 * 1000;
  });

  const refresh_token_id = computed(() => {
    return refreshToken.value.rid;
  });

  const datetime_format_for_table = computed(() => {
    return datetime_format.value.toUpperCase() + " HH:mm:ss";
  });

  const datetime_format_year = computed(() => {
    return datetime_format.value.toUpperCase();
  });

  return {
    accessJwt,
    accessToken,
    refreshJwt,
    id,
    email,
    name,
    time_zone,
    datetime_format,
    datetime_format_for_table,
    role_id,
    role_name,
    auto_logout_timeout,
    setAuthToken,
    clearAuthToken,
    refreshAuthToken,
    startLogoutTimer,
    saveDateTimeFormat,
    saveTimeZone,
    datetime_format_year,
    stopLogoutTimer,
    requestLogout,
  };
});
