const search = {
  title: "검색",
  switch: "상세 검색",
  quick_title: "빠른 검색",
  advanced_title: "상세 검색",
  item: "검색 항목",
  date: "날짜 검색",
  search_btn: "검색",
  datepicker: {
    select: "선택",
    cancel: "취소",
  },
  //상세 날짜 검색 타입
  search_type: {
    presets: "사전 설정",
    date_range: "날짜 범위",
    date_time_range: "날짜 & 시간 범위",
    relative: "상대적 범위",
  },
  // 사전 설정 타입
  preset_type: {
    last_15_minutes: "최근 15분",
    last_60_minutes: "최근 60분",
    last_4_hours: "최근 4시간",
    last_24_hours: "최근 24시간",
    last_3_days: "최근 3일",
    last_7_days: "최근 7일",
    last_30_days: "최근 30일",
    last_60_days: "최근 60일",
  },
  // 상세 검색의 상대적 범위 설정
  start_date: "시작 날짜 :",
  last_date: "마지막 날짜 :",
  seconds_ago: "초 전",
  minutes_ago: "분 전",
  hours_ago: "시 전",
  days_ago: "일 전",
  weeks_ago: "주 전",
  months_ago: "개월 전",
  quarters_ago: "분기 전",
  years_ago: "년 전",
  now: "지금",
  no_snap_to: "스탭 기능 없음",
  beginning_of_seconds: "초의 시작",
  beginning_of_minutes: "분의 시작",
  beginning_of_hours: "시간의 시작",
  beginning_of_days: "일의 시작",
  beginning_of_weeks: "주의 시작",
  beginning_of_months: "월의 시작",
  beginning_of_quarters: "분기의 시작",
  beginning_of_years: "년의 시작",
  beginning_of_the_current_second: "현재 초의 시작",
  beginning_of_the_current_minute: "현재 분의 시작",
  beginning_of_the_current_hour: "현재 시간의 시작",
  beginning_of_the_current_day: "현재 일의 시작",
  beginning_of_the_current_week: "현재 주의 시작",
  beginning_of_the_current_month: "현재 월의 시작",
  beginning_of_the_current_quarter: "현재 분기의 시작",
  beginning_of_the_current_year: "현재 년의 시작",
  //센서현황 > 센서 목록
  sensor_list: {
    version: "버전",
    policy_profile: "정책 프로파일",
    platform: "플랫폼",
    os_version: "OS 버전",
    health_checked_at: "최근 헬스체크 시간",
    created_at: "생성일",
    updated_at: "최근 수정일",
    installed_at: "설치 시간",
    policy_applied_status: "정책적용 상태",
    version_status: "업데이트 상태",
  },
  //수집 현황 > 수집 목록
  collections: {
    id: "파일명",
    format: "파일 포맷",
    extension: "확장자",
    pre_defined: "사전 정의",
    version: "파일 버전",
    digital_signature: "서명 정보",
    paths: "수집 경로",
    sensor_id: "수집된 센서 번호",
    created_at: "수집 시간",
  },
  //사용자 관리 > 역할 관리
  user_role: {
    name: "역할",
    creator_id: "생성자 ID",
    created_at: "생성일",
    updated_at: "최근 수정일",
  },
  //사용자 관리 > 계정 관리
  user_account: {
    email: "아이디",
    name: "이름",
    role: "역할",
    status: "상태",
    created_at: "생성일",
  },
  //정책 관리 > 프로파일 관리
  policy_profiles: {
    policy_profile: "정책 프로파일명",
    sensor_policy: "센서제어 정책명",
    file_colletion: "파일수집 정책명",
    id: "생성자 ID",
    created_at: "생성일",
    updated_at: "최근 수정일",
  },
  //정책 관리 > 센서제어 정책
  sensor_policy: {
    sensor_policy: "센서제어 정책명",
    id: "생성자 ID",
    created_at: "생성일",
    updated_at: "최근 수정일",
  },
  //정책 관리 > 파일 수집 정책
  collection_policy: {
    collection_policy: "파일수집 정책명",
    id: "생성자 ID",
    created_at: "생성일",
    updated_at: "최근 수정일",
  },
  //로그 관리 > 관리콘솔 접속로그
  connection_log: {
    id: "계정 아이디",
    name: "계정 이름",
    role: "역할",
    group: "소속 그룹",
    ip: "접속 IP",
    type: "작업 유형",
    target: "작업 대상",
    result: "작업 상태",
    access_time: "접속 시간",
  },
  //로그 관리 > 센서 로그
  sensor_log: {
    sensor_no: "센서 번호",
    sensor_group: "센서 그룹",
    ip: "접속 IP",
    log_type: "작업 유형",
    log_object: "작업 대상",
    log_result: "작업 상태",
    log_msg: "내용",
  },
  //로그 관리 > 수집 파일 다운로드 관리
  file_log: {
    file_name: "파일명",
    file_format: "파일 포맷",
    file_ext: "확장자",
    file_size: "파일 크기",
    log_result: "작업 상태",
    user_id: "계정 아이디",
    role_name: "역할",
    download_time: "다운로드 시간",
  },
  //설치 패키지 관리 >  기본 셋업본 관리
  bases: {
    platform: "플랫폼",
    sensor_version: "센서 버전",
    registration: "파일 등록일",
  },
  //설치 패키지 관리 >  설치 패키지 관리
  package: {
    name: "파일명",
    platform: "플랫폼",
    sensor_version: "센서 버전",
    token_id: "Token 아이디",
    token_status: "Token 상태",
    registration: "파일 등록일",
    token_created: "토큰 생성일",
  },
  // 환경 설정 > 그룹 관리
  group: {
    group_name: "그룹명",
    created_at: "그룹 생성 시간",
  },
};

export default search;
