import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useMainStore = defineStore("main", () => {
  const lang = ref<string | null>(localStorage.getItem("lang"));
  if (!lang.value) {
    if (navigator.language == "ko-KR" || navigator.language == "en-US") {
      lang.value = navigator.language;
    } else {
      lang.value = "en-US";
    }
  }

  const darkMode = ref(localStorage.getItem("dark") == "true");
  const email = ref<string | null>(localStorage.getItem("email"));
  if (!email.value) {
    email.value = "";
  }

  const language = computed((): string => {
    if (!lang.value) return "en-US";
    else return lang.value;
  });

  const isDarkMode = computed(() => {
    return darkMode.value;
  });

  const userEmail = computed(() => {
    return email.value;
  });

  const setLanguage = (language: string) => {
    if (language == "ko-KR" || language == "en-US") {
      lang.value = language;
    } else {
      lang.value = "en-US";
    }
    //const { locale } = useI18n();
    //locale.value = lang.value;
    localStorage.setItem("lang", lang.value);
  };

  const toggleDarkMode = () => {
    darkMode.value = !darkMode.value;
    localStorage.setItem("dark", darkMode.value.toString());
  };

  const setUserEmail = (userEmail: string) => {
    email.value = userEmail;
    localStorage.setItem("email", email.value);
  };

  return {
    language,
    isDarkMode,
    userEmail,
    setLanguage,
    toggleDarkMode,
    setUserEmail,
  };
});
