const validations = {
  email: "Not a valid email address.",
  ipAddress: "Not a valid IP address.",
  domain: "Not a valid domain",
  minLength: "This field should be at least {min} characters long.",
  minValue: "The minimum value allowed is {min}.",
  maxLength: "The maximum length allowed is {max}",
  maxValue: "The maximum value allowed is {max}.",
  numeric: "Value must be numeric",
  required: "Value is required.",
  sameAs: "The value must be equal to {other}.",
  sameAsPassword: "The value must be equal to the password value.",
  //사용자 정의
  roleName:
    "Only English upper and lower case letters, numbers, and _, - are allowed.",
};

export default validations;
