const admin = {
  precautions_title: "Precautions for registration",
  precautions:
    "Please enter the email address you are using.\nPlease enter your name in English or Korean.\nPlease enter a password between 9 and 32 characters including uppercase/lowercase English letters, numbers, and special characters.\nPlease select within the range of special characters {characters}",
  title: "Admin Registration",
  email: "Email Address",
  name: "Name",
  phone: "Phone Number",
  password: "Password",
  confirm_password: "Confirm Password",
  password_precautions_title: "Precautions for password",
  password_precautions:
    "Please enter a password between 9 and 32 characters including uppercase/lowercase English letters, numbers, and special characters.\nPlease select within the range of special characters {characters}",
  message: "You can log in using your registered admin information.",
  registration: "Registration",
  cancel: "Cancel",
};

export default admin;
