const sensors = {
  sidelab: {
    group_title: "Groups",
    group_all: "All",
    status_group_title: "Groups by status",
  },
  status_unknown: "Unknown",
  status_installed: "Installed",
  status_uninstalled: "Uninstalled",
  status_check_in: "Check In",
  status_check_out: "Check Out",
  status_sleep_in: "Sleep In",
  status_sleep_out: "Sleep Out",
  status_crashed: "Crashed",
  long_term_disconnection: "Long-term disconnection",
  policy_applied: "Applied",
  policy_not_applied: "Not Applied",
  up_to_date: "Up to date",
  out_of_date: "Out of date",
  true: "Up to date",
  false: "Out of date",
  detail: {
    id: "Sensor ID",
    health_checked_at: "Last health check time",
    execution_time: "Policy reception time",
    mac: "Mac Addr",
    sensor_information: "Sensor information summary",
    server_urls: "Management server",
    system_details: "System Details",
    host_name: "Host name",
    domain_name: "Domain name",
    sid: "SID",
    cpu: "CPU",
    memory: "Memory",
    disks: "Disk",
    of: "of",
    available: "available",
    processor_arch: "Processor Architecture",
    os_installed_at: "OS installation time",
    os_build: "OS build number",
    policy_information: "Policy information",
    sensor_control: "Sensor control policy",
    file_collection_policy: "File collection policy",
    minute_interval: "minute interval",
    interval: "Collection period classification",
    sensor_history: "Sensor history",
    file_collection_history: "File collection history",
    sensor_detail_history: "Sensor details history",
    log_type_service: "Service uptime",
    log_type_health: "Health check history",
    policy_update_history: "Policy update history",
    policy_update_success: "Policy update success",
    policy_update_fail: "Policy update fail",
    command_history: "Command history",
    success: "success",
    fail: "fail",
  },
  dialog: {
    title: "Assign Policy Profile",
    group_search: "Group Search",
    group_name: "Group Name",
    sensor_no: "Sensor No",
    profile_name: "Policy Profile",
    sensor_policy_name: "Sensor Policy",
    collection_policy_name: "Collection Profile",
    ok_btn: "Apply",
    cancel_btn: "Cancel",
  },
  table: {
    assign_profile: "Assign Policy Profile",
    collect_sensor_info: "Collect Sensors Info",
    update_sensor: "Update Sensors",
    delete_sensor: "Uninstall Sensors",
    no: "No",
    health_checked_at: "Last Health Check",
    installed_at: "Installed",
    ip: "IP",
    uptime: "Uptime",
    version: "Version",
    policy_profile: "Policy Porfile",
    group_name: "Group Name",
    platform: "Platform",
    os_version: "OS Version",
    status: "Status",
    policy_applied_status: "Policy Applied Status",
    version_status: "Update Status",
    created_at: "Created",
    updated_at: "Updated",
    true: "Applied",
    false: "Not Applied",
  },
  tooltip: {
    show: "Show",
    hide: "Hide",
    minutes: "Minutes",
    tray_settings: "Tray Settings",
    tray_icon: "Tray Icon",
    tray_menu: "Tray Menu",
    sensor_check_interval: "Sensor Check Interval",
    health_check_interval: "Health Check Interval",
    collect_from: "Collect From",
    pe_file: "PE File",
    non_pe_file: "Non-PE File",
    file_size_limits: "File Size Limits",
    collection_interval: "Collection Interval",
    unlimited: "Unlimited",
    collect: "Collect",
    non_collect: "Non-Collect",
  },
};

export default sensors;
