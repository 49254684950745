const login = {
  settings: "Login Settings",
  method: "Login Method",
  method_0: "ID+Password",
  method_1: "ID+Password+OTP",
  method_2: "SAML 2.0",
  lockout: "Account Lockout",
  enabled: "Enabled",
  disabled: "Disabled",
  times: "times",
  try_count_range: "[ 3 times ~ 100 times ]",
  lockout_duration: "Account Lockout Duration",
  lockout_duration_range: "[ 1 minute ~ 60 minutes ]",
  auto_logout: "Auto Logout",
  auto_logout_range: "[ 5 minutes ~ 60 minutes ]",
  password_settings: "Password Settings",
  check_previous_password: "Check Previous Password",
  confirmation_mail_duration: "Confirmation Mail Validity Time",
  minutes: "Minutes",
  hours: "Hours",
  confirmation_mail_duration_range:
    "[Within up to 1 day / Within 24 hours / Within 1,440 minutes]",
};

export default login;
