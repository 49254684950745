const alert = {
  dont_have_permission_to_access: "접근 권한이 없습니다.",
  authentication_has_expired: "인증 정보가 만료되었습니다.",
  permission_info_does_not_exist: "권한 정보가 존재하지 않습니다.",
  // 공통
  internal_error_occurred: "내부 오류({status})가 발생하였습니다.",
  try_again_in_a_few_minutes: "잠시 후 다시 시도해주시기 바랍니다.",
  check_and_try_again: "확인 후 다시 시도해주시기 바랍니다.",
  request_is_invalid: "요청 정보가 유효하지 않습니다.",
  saved_successfully: "정상적으로 저장되었습니다.",
  added_suceessfully: "정상적으로 추가되었습니다.",
  deleted_successfully: "정상적으로 삭제되었습니다.",
  updated_successfully: "정상적으로 업데이트되었습니다.",
  unsaved_data_are_you_sure_you_want_to_leave:
    "저장되지 않은 데이터가 있습니다. 정말 이동하시겠습니까?",
  invalid_email: "유효한 이메일 주소가 아닙니다.",
  invalid_password_length: "비밀번호가 길이가 유효하지 않습니다.",
  invalid_password_format: "비밀번호 형식이 유효하지 않습니다.",
  not_support_clipboard_api: "브라우저가 클립보드 API를 지원하지 않습니다.",
  name_already_exists: "동일한 이름이 존재합니다.",
  contact_admin: "관리자에게 문의하시기 바랍니다.",
  invalid_format: "유효한 형식은 IP, URL, 도메인입니다.",
  // 로그인(account)
  id_is_not_active: "계정이 비활성화 상태입니다.",
  id_does_not_exist: "계정 정보가 존재하지 않습니다.",
  name_does_not_exist: "사용자 이름이 존재하지 않습니다.",
  email_already_exists: "이미 사용하고 있는 이메일 주소 입니다.",
  email_does_not_exist: "이메일 주소가 존재하지 않습니다.",
  password_mismatch: "비밀번호가 일치하지 않습니다.",
  new_password_equal: "새비밀번호가 기존 비밀빈호와 동일합니다.",
  not_allowed_ip: "접속이 허용되지 않는 IP입니다.",
  invalid_query: "검색이 유효하지 않습니다.",
  invalid_parameters: "파리미터가 유효하지 않습니다.",
  account_locked: "계정이 잠긴 상태입니다.",
  invalid_passcode: "OTP 코드가 유효하지 않습니다.",
  passcode_required: "OTP 코드 입력이 필요합니다.",
  otp_secret_required: "OTP 비밀키 설정이 필요합니다.",
  // 로그인(token)
  token_not_found_in_header: "요청 정보(토큰)가 유효하지 않습니다.",
  invalid_token: "토큰 정보가 유효하지 않습니다.",
  expired_token: "토큰 정보가 만료되었습니다.",
  token_not_exist: "토큰 정보가 존재하지 않습니다.",
  // 비밀번호 찾기
  email_sent_successfully: "이메일이 정상적으로 발송되었습니다.",
  // 등록 ID를 이용한 비밀번호 설정
  expired_register_id: "계정 등록 정보가 만료되었습니다.",
  register_id_does_not_exist: "계정 등록 정보가 존재하지 않습니다.",
  // 비밀번호 재설정
  expired_reset_id: "비밀번호 재설정 정보가 만료되었습니다.",
  reset_id_does_not_exist: "비밀번호 재설정 정보가 존재하지 않습니다.",
  try_forgot_password_again: '"비밀번호 찾기"를 다시 시도해주시기 바랍니다.',
  // 프로파일>비밀번호 변경
  captcha_verified_successfully: "자동입력 문자가 정상적으로 확인되었습니다.",
  captcha_mismatch: "자동입력 문자가 일치하지 않습니다.",
  captcha_required: "자동입력 문자 확인 후 진행하시기 바랍니다.",
  //센서 현황>센서 목록
  cmd_collect_sensor_info: "센서 정보 수집",
  cmd_update_sensor: "센서 업데이트",
  cmd_uninstall_sensor: "센서 삭제",
  send_cmd_successfully: "명령이 정상적으로 전송되었습니다.",
  send_cmd_to_all: "전체 센서에 [{cmd}] 명령을 전송하시겠습니까?",
  send_cmd_to_sensor:
    "선택된 {count}개의 센서에 [{cmd}] 명령을 전송하시겠습니까?",
  send_cmd_to_group: "[{group}] 그룹에 [{cmd}] 명령을 전송하시겠습니까?",
  cant_be_used_to_all: "[{cmd}] 명령은 전체를 대상으로 사용할 수 없습니다.",
  no_sensor: "센서가 존재하지 않는 그룹에는 명령을 전송할 수 없습니다.",
  // 수집 현황>수집 목록
  no_data_to_download: "다운로드할 데이터가 없습니다.",
  select_data_to_download: "다운로드할 데이터를 선택해야 합니다.",
  file_downloaded: "파일이 정상적으로 다운되었습니다.",
  package_downloaded: "패키지가 정상적으로 다운되었습니다.",
  select_data_to_copy: "복사할 데이터를 선택해야 합니다.",
  id_copied: "파일 ID가 클립보드에 복사되었습니다.",
  // 사용자 관리>역할 관리
  no_selected_roles: "삭제할 계정 역할을 먼저 선택해야 합니다.",
  cant_delete_system_roles: "시스템에서 생성한 계정 역할은 삭제할 수 없습니다.",
  in_use: "사용 중인 역할은 삭제할 수 없습니다.",
  // 사용자 관리>계정 관리
  cant_change_status_current_account:
    "현재 로그인 계정의 상태는 변경할 수 없습니다.",
  no_selected_users: "삭제할 계정을 먼저 선택해야 합니다.",
  cant_delete_current_account: "현재 로그인 계정은 삭제할 수 없습니다.",
  cant_delete_last_admin: "마지막 admin 계정은 삭제할 수 없습니다.",
  // 정책관리>프로파일 관리
  no_selected_sensor: "센서제어 정책명을 선택해야 합니다.",
  no_selected_collection: "파일수집 정책명을 선택해야 합니다.",
  // 정책관리>센서제어 정책
  no_selected_days: "요일을 선택해야 합니다.",
  start_must_less_than_end: "시작 시간은 종료 시간보다 작아야 합니다.",
  diff_between_start_end:
    "분 단위는 시작 시간과 종료 시간의 차이보다 작아야 합니다.",
  enter_minimum_maximum_minute:
    "최소 1분 ~ 최대 1440분 사이로 입력해야 합니다.",
  no_selected_policy: "삭제할 정책을 먼저 선택해야 합니다.",
  must_enter_period: "주기를 입력해야 합니다.",
  must_enter_minute: "범위를 입력해야 합니다.",
  server_limit: "관리서버의 최대 허용 등록 개수는 4입니다.",
  no_selected_server: "삭제할 관리서버를 선택해야 합니다.",
  must_select_version: "센서 버전을 선택해야 합니다.",
  // 정책관리>파일수집 정책
  within_300_minutes: "300분 이내로 입력해야 합니다.",
  cant_delete_in_use_policy: "사용중인 정책은 삭제할 수 없습니다.",
  file_size_limit: "수집 파일 제한 크기는 1이상 50이하로 입력하여야 합니다.",
  // 로그관리>관리콘솔 접속로그
  log_downloaded: "로그가 정상적으로 다운되었습니다.",
  // 설치패키지관리>기본셋업본 관리
  file_registered: "파일이 등록되었습니다.",
  no_selected_file: "선택된 파일이 없습니다.",
  must_select_file_first: "삭제할 파일을 먼저 선택해야 합니다.",
  cant_delete_last_file: "마지막 파일은 삭제할 수 없습니다.",
  // 설치패키지관리>설치패키지 관리
  no_selected_pkg: "선택된 패키지가 없습니다.",
  select_download_pkg_first: "다운로드할 패키지를 먼저 선택해야 합니다.",
  token_expiration_reset: "토큰 유효기간이 재설정되었습니다.",
  // 설치패키지관리>설치패키지빌더
  installation_pkg_created: "설치 패키지가 정상적으로 생성되었습니다.",
  token_limit: "Token 유효기간은 7일이상 365일 이하로 설정해야 합니다.",
  // 환경설정>시스템관리
  certification_required: "인증 테스트가 필요합니다.",
  account_verification_required: "계정 인증이 필요합니다.",
  email_verification_required: "이메일 인증이 필요합니다.",
  must_enter_ip_server: "메일 서버 IP 주소를 입력하여야 합니다.",
  email_authentication_pass: "이메일 인증 테스트가 성공하였습니다.",
  must_enter_key: "계정 ID를 입력하여야 합니다.",
  must_enter_secret_key: "계정 비밀번호를 입력하여야 합니다.",
  must_enter_host: "서버 주소를 입력하여야 합니다.",
  must_enter_port: "포트를 입력하여야 합니다.",
  must_select_group: "지역을 선택하여야 합니다.",
  account_verification_passed: "계정 인증 테스트가 성공하였습니다.",
  email_changed_rerun_test:
    "수정된 정보가 있습니다. 이메일 인증테스트를 다시 진행해야 합니다.",
  server_changed_rerun_test:
    "수정된 정보가 있습니다. 계정 인증테스트를 다시 진행해야 합니다.",
  longterm_limit:
    "센서 장기미접속 판단 주기는 최대 180일 또는 25주 이내로 입력해야 합니다.",
  email_verification_failed: "이메일 인증이 실패하였습니다.",
  account_verification_failed: "계정 인증이 실패하였습니다.",
  enter_license_expire: "라이선스 만료일을 입력해야 합니다.",
  enter_disk_usage: "디스크 공간을 입력해야 합니다.",
  // 환경설정 > 그룹관리
  no_seleted_group: "선택된 그룹이 없습니다.",
  must_select_group_first: "삭제할 그룹을 먼저 선택해야 합니다.",
  cant_delete_default_group: "Default Group은 삭제할 수 없습니다.",
  prefix_limit: "PC 호스트명은 5자리 이내로 입력해야 합니다.",
  ip_address_incorrect: "입력된 IP 주소가 올바르지 않습니다.",
  duplicate_name: "중복된 그룹명을 사용할 수 없습니다.",
};

export default alert;
