import myprofileRoutes from "./myprofileRoutes";
import sensorRoutes from "./sensorsRoutes";
import collectionsRoutes from "./collectionsRoutes";
import usersRoutes from "./usersRoutes";
import policyRoutes from "./policyRoutes";
import auditRoutes from "./auditRoutes";
import packagesRoutes from "./packagesRoutes";
import settingsRoutes from "./settingsRoutes";

const dashboardRoutes = [
  {
    path: "/",
    redirect: {
      name: "dashboard",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { authRequired: true, aclName: "dashboard" },
    component: () =>
      import(
        /* webpackChunckName: "dashboard" */ "../views/NrDashboardView.vue"
      ),
    children: [
      {
        path: "",
        name: "main",
        component: () =>
          import(
            /* webpackChunckName: "main" */ "../views/dashboard/NrMainView.vue"
          ),
      },
      ...myprofileRoutes,
      ...sensorRoutes,
      ...collectionsRoutes,
      ...usersRoutes,
      ...policyRoutes,
      ...auditRoutes,
      ...packagesRoutes,
      ...settingsRoutes,
    ],
  },
];

export default dashboardRoutes;
