const audit = {
  log_management: "Log management",
  access_log: "Management console access log",
  sensor_log: "Sensor access log",
  audit_log: "Management console audit log",
  download_log: "Download log",
  download: "Download",
  access_time: "Access time",
  id: "Account ID",
  name: "Account ID",
  role: "Account role",
  ip: "Access IP",
  type: "Work type",
  target: "Working target",
  status: "Work status",
  contents: "Contents",
  header: "Header field",
  connection_log: {
    role: "Role",
    user: "User",
    console_connection: "Console connection",
    policy: "Policy",
    audit: "Audit",
    settings: "Settings",
    package: "Package",
    file: "Files",
    sensor_manage: "Sensor manage",
    sensor: "Sensor",
    datastats: "Datastats",
    login: "Login",
    logout: "Logout",
    id_not_active: "Id is not active",
    id_not_exist: "Id does not exist",
    email_exists: "Email already exists",
    email_not_exist: "Email does not exist",
    password_mismatch: "Password mismatch",
    password_equal: "New password equal",
    expired_id: "Expired register id",
    register_id_not_exist: "Register id does not exist",
    expired_reset_id: "Expired reset id",
    reset_not_exist: "Reset id does not exist",
    not_allowed_ip: "Not allowed ip",
    invalid_query: "Invalid query",
    invalid_parameters: "Invalid parameters",
    permission_denied: "Permission denied",
    account_locked: "Account locked",
    invalid_email: "Invalid email",
    invalid_id: "Invalid id",
    invalid_ip: "Invalid ip",
    invalid_password_format: "Invalid password format",
    invalid_password_length: "Invalid password length",
    invalid_token: "Invalid token",
    expired_token: "Expired token",
    token_not_exists: "Token not exists",
    role_already: "Role name already exists",
    internal_error: "Internal error",
    1: "Success",
    2: "Failure",
  },
  console_log: {
    user_info_update: "User info update",
    password_change: "Password change",
    apikey_change: "API KEY change",
    status_change: "User status modification",
    user_delete: "User deletion",
    user_activate: "User activation",
    password_set: "Password setting",
    password_reset: "Password reset",
    password_forgot: "Password initialization",
    otp_set: "OTP setting",
    otp_reset: "OTP reset",
    otp_forgot: "OTP initialization",
    signup: "Sign-up",
    role_create: "Role creation",
    role_delete: "Role deletion",
    role_update: "Role modification",
    log_download: "Log file download",
    system_settings_set: "System management information setting",
    group_settings_set: "Group management information setting",
    login_settings_set: "Login management information setting",
    group_info_add: "Group information addition",
    group_info_delete: "Group information deletion",
    ip_group_info_update: "IP group information modification",
    server_connection_check: "Server connection check",
    policy_profile_add: "Policy profile addition",
    policy_profile_delete: "Policy profile deletion",
    policy_profile_update: "Policy profile modification",
    policy_sensor_add: "Sensor control policy addition",
    policy_sensor_delete: "Sensor control policy deletion",
    policy_sensor_update: "Sensor control policy modification",
    policy_collection_add: "File collection policy addition",
    policy_collection_delete: "File collection policy deletion",
    policy_collection_update: "File collection policy modification",
    base_package_add: "Base package addition",
    base_package_delete: "Base package deletion",
    sensor_package_add: "Installation package addition",
    sensor_package_delete: "Installation package deletion",
    sensor_package_expired_time_reset:
      "Installation package token expiration reset",
    sensor_package_download: "Sensor package download",
    policy_assign_to_group: "Policy assignment to group",
    policy_assign_to_sensor: "Policy assignment to sensor",
    file_upload: "File upload",
    file_delete: "File deletion",
    long_term_absense_sensor_patch_count:
      "Long-term absense sensor patch count",
  },
  sensor: {
    sensor_no: "Sensor no",
    sensor_group: "Sensor group",
    sensor: "Sensor",
    sensor_command: "Command",
    sensor_upsert: "Add / Update Sensor Information",
    sensor_create: "Add Sensor Information",
    sensor_update: "Update Sensor Information",
    sensor_delete: "Delete Sensor",
    sensor_update_status: "Update Sensor Status",
    sensor_update_systeminfo: "Update Sensor System Information",
    sensor_alive: "Sensor Health Check",
    sensor_patch_policy_status: "Patch Policy Application Status for Sensor",
    sensor_check_token_validation: "Check Sensor Installation Token Validation",
    sensor_version_update: "Update Sensor Version",
    sensorpkg_download: "Download Sensor Package",
    sensor_add_error_log: "Add Sensor Error Log",
    command_add: "Add Command",
    day: "day",
    hour: "hour",
    datastats_add_daily: "Datastats add daily",
    datastats_add_monthly: "Datastats add monthly",
    file_status_patch: "File status patch",
    file_basic_add: "File basic add",
    file_basic_sensorinfo_add: "File basic sensorinfo add",
  },
  file_log: {
    download_time: "Download time",
    file_name: "File name",
    file_format: "File format",
    file_ext: "File ext",
    file_size: "File size",
    log_msg: "Contents",
    user_id: "User id",
    role_name: "Role",
    executable: "Executable file",
    non_executable: "Non-executable files",
  },
  msg: {
    sensor_package_name: "Sensor Package Name",
    day: "Day",
    sensor_policy_name: "Sensor Policy Name",
    group_name: "Group Name",
    range: "Range",
    long_term_absence_sensor_patch_count:
      "Long-term Absence Sensor Patch Count",
    collection_policy_name: "Collection Policy Name",
    profile_name: "Profile Name",
    role_name: "Role Name",
    user_id: "User Id",
    base_package_platform: "Base Package Platform",
    base_package_version: "Base Package Version",
    command_info: "Command Info",
    sensor_package_veresion: "Sensor Package Veresion",
    host_name: "Host name",
    architecture: "Architecture",
    package_version: "Package Version",
    package_platform: "Package Platform",
    format: "Format",
    sha256: "SHA-256",
    status: "Status",
    command_count: "Command Count",
    path: "Path",
    internal: "internal error",
    month: "Month",
    token_id: "Token id",
    add_first_admin: "Add first admin",
    add_user: "Add user",
  },
};

export default audit;
