const validations = {
  email: "유효한 이메일 주소가 아닙니다.",
  ipAddress: "유효한 IP 주소가 아닙니다.",
  domain: "유효한 도메인이 아닙니다.",
  minLength: "최소 {min}자 이상 입력하셔야 합니다.",
  minValue: "허용되는 최소값은 {min}입니다.",
  maxLength: "허용되는 최대 길이는 {max}자 입니다",
  maxValue: "허용되는 최대값은 {max}입니다.",
  numeric: "숫자값을 입력하셔야 합니다.",
  required: "필수 입력 항목입니다.",
  sameAs: "이 값은 {other}값과 동일해야 합니다.",
  sameAsPassword: "이 값은 비밀번호값과 동일해야 합니다.",
  //사용자 정의
  roleName: "영어 대/소문자, 숫자, _, - 만 입력가능합니다.",
};

export default validations;
