const auditRoutes = [
  {
    path: "/audit/console-connection",
    name: "audit-console-connection",
    meta: { aclName: "audit" },
    component: () =>
      import(
        /* webpackChunckName: "audit" */ "../views/dashboard/audit/NrConsoleConnectionView.vue"
      ),
  },
  {
    path: "/audit/console",
    name: "audit-console",
    meta: { aclName: "audit" },
    component: () =>
      import(
        /* webpackChunckName: "audit" */ "../views/dashboard/audit/NrConsoleView.vue"
      ),
  },
  {
    path: "/audit/sensor",
    name: "audit-sensor",
    meta: { aclName: "audit" },
    component: () =>
      import(
        /* webpackChunckName: "audit" */ "../views/dashboard/audit/NrSensorView.vue"
      ),
  },
  {
    path: "/audit/filedownload",
    name: "audit-filedownload",
    meta: { aclName: "audit" },
    component: () =>
      import(
        /* webpackChunckName: "audit" */ "../views/dashboard/audit/NrFileDownloadView.vue"
      ),
  },
];

export default auditRoutes;
