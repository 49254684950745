const settingsRoutes = [
  {
    path: "/settings/system",
    name: "settings-system",
    meta: { aclName: "settings" },
    component: () =>
      import(
        /* webpackChunckName: "settings" */ "../views/dashboard/settings/NrSystemView.vue"
      ),
  },
  {
    path: "/settings/login",
    name: "settings-login",
    meta: { aclName: "settings" },
    component: () =>
      import(
        /* webpackChunckName: "settings" */ "../views/dashboard/settings/NrLoginView.vue"
      ),
  },
  {
    path: "/settings/group",
    name: "settings-group",
    meta: { aclName: "settings" },
    component: () =>
      import(
        /* webpackChunckName: "settings" */ "../views/dashboard/settings/NrGroupView.vue"
      ),
  },
  {
    path: "/settings/license",
    name: "settings-license",
    meta: { aclName: "settings" },
    component: () =>
      import(
        /* webpackChunckName: "settings" */ "../views/dashboard/settings/NrLicenseView.vue"
      ),
  },
];

export default settingsRoutes;
