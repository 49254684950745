const search = {
  title: "Search",
  switch: "Advanced Search",
  quick_title: "Quick Search",
  advanced_title: "Advanced Search",
  item: "Item",
  date: "Date",
  search_btn: "Search",
  datepicker: {
    select: "Select",
    cancel: "Cancel",
  },
  //상세 날짜 검색 타입
  search_type: {
    presets: "Presets",
    date_range: "Date Range",
    date_time_range: "Date & Time Range",
    relative: "Relative",
  },
  // 상세 검색의 상대적 범위 설정
  start_date: "Start Date :",
  last_date: "Last Date :",
  seconds_ago: "Seconds ago",
  minutes_ago: "Minutes ago",
  hours_ago: "Hours ago",
  days_ago: "Days ago",
  weeks_ago: "Weeks ago",
  months_ago: "Months ago",
  quarters_ago: "Quaters ago",
  years_ago: "Years ago",
  now: "Now",
  no_snap_to: "No Snap-to",
  beginning_of_seconds: "Beginning of Seconds",
  beginning_of_minutes: "Beginning of Minutes",
  beginning_of_hours: "Beginning of Hours",
  beginning_of_days: "Beginning of Days",
  beginning_of_weeks: "Beginning of Weeks",
  beginning_of_months: "Beginning of Months",
  beginning_of_quarters: "Beginning of Quarters",
  beginning_of_years: "Beginning of Years",
  beginning_of_the_current_second: "Beginning of the current Second",
  beginning_of_the_current_minute: "Beginning of the current Minute",
  beginning_of_the_current_hour: "Beginning of the current Hour",
  beginning_of_the_current_day: "Beginning of the current Day",
  beginning_of_the_current_week: "Beginning of the current Week",
  beginning_of_the_current_month: "Beginning of the current Month",
  beginning_of_the_current_quarter: "Beginning of the current Quarter",
  beginning_of_the_current_year: "Beginning of the current Year",
  // 사전 설정 타입
  preset_type: {
    last_15_minutes: "Last 15 Minutes",
    last_60_minutes: "Last 60 Minutes",
    last_4_hours: "Last 4 Hours",
    last_24_hours: "Last 24 Hours",
    last_3_days: "Last 3 Days",
    last_7_days: "Last 7 Days",
    last_30_days: "Last 30 Days",
    last_60_days: "Last 60 Days",
  },
  //센서현황 > 센서 목록
  sensor_list: {
    version: "Version",
    policy_profile: "Policy Profile",
    platform: "Platform",
    os_version: "OS Version",
    health_checked_at: "Last Health Check",
    created_at: "Created",
    updated_at: "Updated",
    installed_at: "Installed",
    policy_applied_status: "Policy Applied Status",
    version_status: "Update Status",
  },
  //수집 현황 > 수집 목록
  collections: {
    id: "File Name",
    format: "Format",
    extension: "Extension",
    pre_defined: "Pre Defined",
    version: "Version",
    digital_signature: "Signature",
    paths: "Paths",
    sensor_id: "Sensor Id",
    created_at: "Created",
  },
  //사용자 관리 > 역할 관리
  user_role: {
    name: "Role",
    creator_id: "Creator ID",
    created_at: "Created",
    updated_at: "Updated",
  },
  //사용자 관리 > 계정 관리
  user_account: {
    email: "ID",
    name: "Name",
    role: "Role",
    status: "Status",
    created_at: "Created",
  },
  //정책 관리 > 프로파일 관리
  policy_profiles: {
    policy_profile: "Policy name",
    sensor_policy: "Sensor name",
    file_colletion: "Collection name",
    id: "ID",
    created_at: "Created",
    updated_at: "Updated",
  },
  //정책 관리 > 센서제어 정책
  sensor_policy: {
    sensor_policy: "Sensor name",
    id: "ID",
    created_at: "Created",
    updated_at: "Updated",
  },
  //정책 관리 > 파일 수집 정책
  collection_policy: {
    collection_policy: "Collection name",
    id: "ID",
    created_at: "Created",
    updated_at: "Updated",
  },
  //로그 관리 > 관리콘솔 접속로그
  connection_log: {
    id: "Account ID",
    name: "Account name",
    role: "Account role",
    group: "Affiliated group",
    ip: "Access IP",
    type: "Work type",
    target: "Working target",
    result: "Connection result",
    access_time: "Access time",
  },
  //로그 관리 > 센서 로그
  sensor_log: {
    sensor_no: "Sensor ip",
    sensor_group: "Sensor group",
    ip: "IP",
    log_type: "Work type",
    log_object: "Working target",
    log_result: "Connection result",
    log_msg: "Contents",
  },
  //로그 관리 > 수집 파일 다운로드 관리
  file_log: {
    file_name: "File name",
    file_format: "File format",
    file_ext: "File ext",
    file_size: "File size",
    log_result: "Connection result",
    user_id: "User id",
    role_name: "Role",
    download_time: "Download time",
  },
  //설치 패키지 관리 >  기본 셋업본 관리
  bases: {
    default_name: "Default setup file name",
    platform: "Platform",
    sensor_version: "Sensor version",
    architecture: "Architecture",
    registration: "Registration date",
  },
  //설치 패키지 관리 >  설치 패키지 관리
  package: {
    name: "File name",
    platform: "Platform",
    sensor_version: "Sensor version",
    token_id: "Token ID",
    token_status: "Token status",
    registration: "Registration date",
    token_created: "Token creation date",
  },
  // 환경 설정 > 그룹 관리
  group: {
    group_name: "Group Name",
    created_at: "Created",
  },
};

export default search;
