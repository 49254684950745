import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { beforeEachGuard } from "./guards";
import loginRoutes from "./loginRoutes";
import dashboardRoutes from "./dashboardRoutes";

const routes: Array<RouteRecordRaw> = [
  ...loginRoutes,
  ...dashboardRoutes,
  // 이외 모든 경로는 Home 으로 이동
  {
    path: "/:catchAll(.*)",
    redirect: {
      name: "main",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(beforeEachGuard);

export default router;
