const breadcrumb = {
  home: "Home",
  dashboard: "Dashboard",
  sensors: "Sensors",
  sensor_list: "Sensor List",
  sensor_detail: "Sensor Information",
  collections: "Collections",
  collection_list: "Collection List",
  collection_detail: "File Information",
  users: "Users",
  roles: "Roles",
  accounts: "Accounts",
  policy: "Policy",
  policy_profile: "Policy Profile",
  sensor_policy: "Sensor Policy",
  file_collection_policy: "File Collection Policy",
  logs: "Logs",
  console_connection_log: "Console Connection Log",
  console_audit_log: "Console Audit Log",
  sensor_log: "Sensor Log",
  file_download_log: "File Download Log",
  packages: "Packages",
  base_packages: "Base Packages",
  install_packages: "Install Packages",
  packages_builder: "Package Builder",
  settings: "Settings",
  system_settings: "System",
  login_settings: "Login",
  group_settings: "Group",
  license_settings: "License",
};

export default breadcrumb;
