import group from "./group";
import license from "./license";
import login from "./login";
import system from "./system";

const settings = {
  group,
  license,
  login,
  system,
};

export default settings;
