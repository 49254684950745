const sensors = {
  sidelab: {
    group_title: "그룹 현황",
    group_all: "전체",
    status_group_title: "센서 상태별 그룹",
  },
  status_unknown: "알수없음",
  status_installed: "설치",
  status_uninstalled: "삭제",
  status_check_in: "시작",
  status_check_out: "종료",
  status_sleep_in: "절전 모드",
  status_sleep_out: "절전 모드 해제",
  status_crashed: "크래시 발생",
  long_term_disconnection: "장기 미접속",
  policy_applied: "적용",
  policy_not_applied: "미적용",
  up_to_date: "최신",
  out_of_date: "오래됨",
  true: "최신",
  false: "오래됨",
  detail: {
    id: "센서 아이디",
    health_checked_at: "최근 헬스체크 시간",
    execution_time: "정책 수신 시간",
    mac: "Mac Addr",
    sensor_information: "센서 정보 요약",
    server_urls: "관리 서버",
    system_details: "시스템 상세 정보",
    host_name: "호스트 이름",
    domain_name: "도메인 이름",
    sid: "보안 식별자",
    cpu: "CPU",
    memory: "Memory",
    disks: "디스크",
    of: "중",
    available: "사용가능",
    processor_arch: "프로세서 아키텍처",
    os_installed_at: "OS 설치 시간",
    os_build: "OS 빌드 번호",
    policy_information: "정책 정보",
    sensor_control: "센서 제어 정책",
    file_collection_policy: "파일 수집 정책",
    minute_interval: "분 간격",
    interval: "수집 기간 구분",
    sensor_history: "센서 이력",
    file_collection_history: "파일 수집 이력",
    sensor_detail_history: "센서 상세 이력",
    log_type_service: "서비스 가동",
    log_type_health: "헬스 체크",
    policy_update_history: "정책 업데이트 이력",
    policy_update_success: "정책 업데이트 성공",
    policy_update_fail: "정책 업데이트 실패",
    command_history: "명령 처리 이력",
    success: "성공",
    fail: "실패",
  },
  dialog: {
    title: "정책 프로파일 할당",
    group_search: "그룹 검색",
    group_name: "그룹명",
    sensor_no: "센서 번호",
    profile_name: "정책 프로파일명",
    sensor_policy_name: "센서 제어 정책명",
    collection_policy_name: "파일 수집 정책명",
    ok_btn: "적용",
    cancel_btn: "취소",
  },
  table: {
    assign_profile: "정책 프로파일 할당",
    collect_sensor_info: "센서 정보 수집",
    update_sensor: "센서 업데이트",
    delete_sensor: "센서 삭제",
    no: "센서 번호",
    health_checked_at: "최근 헬스체크 시간",
    installed_at: "설치 시간",
    ip: "IP",
    uptime: "가동 시간",
    version: "버전",
    policy_profile: "정책 프로파일",
    group_name: "그룹명",
    platform: "플랫폼",
    os_version: "OS 버전",
    status: "상태",
    policy_applied_status: "정책적용 상태",
    version_status: "업데이트 상태",
    created_at: "생성일",
    updated_at: "최근 수정일",
    true: "적용",
    false: "미적용",
  },
  tooltip: {
    show: "표시",
    hide: "숨김",
    minutes: "분",
    tray_settings: "트레이 설정",
    tray_icon: "트레이 아이콘",
    tray_menu: "트레이 메뉴",
    sensor_check_interval: "센스 체크 주기",
    health_check_interval: "헬스 체크 주기",
    collect_from: "수집 대상",
    pe_file: "실행 파일",
    non_pe_file: "비-실행파일",
    file_size_limits: "수집 파일 제한 크기",
    collection_interval: "수집 주기",
    unlimited: "제한없음",
    collect: "수집",
    non_collect: "비수집",
  },
};

export default sensors;
