const users = {
  dialog: {
    title: "계정",
    info: "계정 정보",
    id: "아이디",
    role: "역할",
    name: "이름",
    department: "소속부서",
    job_title: "직급(직무)",
    phone: "전화번호",
    mobile: "휴대번호",
    console_allowed_ip: "관리콘솔 접속 허용 IP",
    download_allowed_ip: "다운로드 허용 IP",
    language: "초대 메일 언어 설정",
    ko_kr: "한국어",
    en_us: "English",
    enabled: "사용함",
    disabled: "사용안함",
    add_btn: "추가",
    delete_btn: "삭제",
    ok_btn: "저장",
    cancel_btn: "취소",
  },
  table: {
    create: "계정 생성",
    delete: "계정 삭제",
    email: "아이디",
    name: "이름",
    role_id: "역할",
    created_at: "생성일",
    status: "상태",
    active: "활성",
    inactive: "비활성",
    resend_email: "이메일 재발송",
  },
};

export default users;
