import {
  AuthToken,
  ApiKeyParams,
  PasswordParams,
  StatusParams,
  InvitationParams,
  User,
  UserInfoParams,
  SignupParams,
  LoginParams,
  RefreshParams,
  ForgotParams,
  ResetPasswordParams,
  SetPasswordParams,
  ApiKeyResponse,
  OtpUrlResponse,
} from "@/types/user";
import { RestClient, GetError } from "@/api/restclient";
import { SearchParam, RowsInfo } from "@/types/search";

export const UserApi = {
  Search: async <T>(
    sp: SearchParam
  ): Promise<[number, string | RowsInfo<T>]> => {
    try {
      const { data, status } = await RestClient.post<RowsInfo<T>>(
        `/users/search`,
        sp
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // admin 계정이 등록되어 있는지 확인한다
  IsAdminRegistered: async (): Promise<[number, string]> => {
    try {
      console.log("UserApi, IsAdminRegistered");
      const { data, status } = await RestClient.get<string>(`/users/admin`);
      console.log(
        "UserApi, IsAdminRegistered, status: %s, data: %s",
        status,
        data
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 내부 ID값을 사용하여 사용자 계정 정보를 조회한다
  GetById: async (id: string): Promise<[number, User | string]> => {
    try {
      const { data, status } = await RestClient.get<User>(`/users/${id}`);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 이메일 주소를 이용하여 사용자 계정 정보를 조회한다
  GetByEmail: async (email: string): Promise<[number, User | string]> => {
    try {
      const { data, status } = await RestClient.get<User>(
        `/users/email/${email}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 계정 등록 ID를 이용하여 사용자 계정 정보를 조회한다
  GetByRegisterId: async (rid: string): Promise<[number, User | string]> => {
    try {
      const { data, status } = await RestClient.get<User>(
        `/users/invitation/${rid}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 비밀번호 재설정 ID를 이용하여 사용자 계정 정보를 조회한다
  GetByResetId: async (rid: string): Promise<[number, User | string]> => {
    try {
      const { data, status } = await RestClient.get<User>(
        `/users/password/reset/${rid}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 사용자 계정 정보를 수정한다
  UpdateUserInfoById: async (ui: UserInfoParams): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.patch<string>(
        `/users/${ui.id}`,
        ui
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 사용자 email 정보를 이용하여 계정 정보를 수정한다
  UpdateUserInfoByEmail: async (
    ui: UserInfoParams
  ): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.patch<string>(
        `/users/email/${ui.email}`,
        ui
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 사용자 비밀번호를 수정한다
  ChangePasswordById: async (p: PasswordParams): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.patch<string>(
        `/users/${p.id}/password`,
        p
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 사용자 API Key를 변경한다
  ChangeApiKeyById: async (
    id: string,
    password: string
  ): Promise<[number, string]> => {
    try {
      const params: ApiKeyParams = {
        id: id,
        password: password,
      };
      const { data, status } = await RestClient.patch<ApiKeyResponse>(
        `/users/${id}/apikey`,
        params
      );
      return [status, data.api_key];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 사용자 상태를 수정한다
  ChangeStatusById: async (p: StatusParams): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.patch<string>(
        `/users/${p.id}/status`,
        p
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 초대 메일 재발송
  SendInvitationById: async (id: string): Promise<[number, string]> => {
    try {
      const params: InvitationParams = {
        id: id,
        base_url: process.env.VUE_APP_CONSOLE_URL as string,
      };
      console.log("SendInvitationById, params: ", params);
      const { data, status } = await RestClient.post<string>(
        `/users/${id}/invitation`,
        params
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // Admin 계정을 등록한다
  AdminSignUp: async (p: SignupParams): Promise<[number, User | string]> => {
    try {
      const { data, status } = await RestClient.post<User>(`/users/admin`, p);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 사용자 계정을 등록한다
  SignUp: async (p: SignupParams): Promise<[number, User | string]> => {
    try {
      const { data, status } = await RestClient.post<User>(`/users`, p);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 사용자 계정을 삭제한다
  Delete: async (id: string): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.delete<string>(`/users/${id}`);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 사용자 계정을 활성화 시킨다
  Activate: async (rid: string): Promise<[number, User | string]> => {
    try {
      const { data, status } = await RestClient.get<User>(
        `/users/registration/${rid}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 사용자 비밀번호 변경 요청
  ForgotPassword: async (p: ForgotParams): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.patch<string>(
        `/users/password/forgot`,
        p
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 비밀번호 재설정 ID를 사용하여 사용자 비밀번호를 변경
  ResetPassword: async (p: ResetPasswordParams): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.patch<string>(
        `/users/password/reset/${p.reset_id}`,
        p
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 계정 등록 ID를 사용하여 사용자 비밀번호를 설정한다
  SetPassword: async (p: SetPasswordParams): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.patch<string>(
        `/users/invitation/${p.register_id}/password`,
        p
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 사용 가능한 도메인 정보를 조회한다
  GetDomains: async (): Promise<[number, string[] | string]> => {
    try {
      const { data, status } = await RestClient.get<string[]>(
        `/users/settings/domains`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 사용자 계정 로그인
  Login: async (params: LoginParams): Promise<[number, AuthToken | string]> => {
    try {
      const { data, status } = await RestClient.post<AuthToken>(
        `/auth`,
        params
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // 인증 토큰 정보를 갱신한다
  Refresh: async (
    accessJwt: string | null,
    refreshJwt: string | null
  ): Promise<[number, AuthToken | string]> => {
    try {
      if (!accessJwt) accessJwt = "";
      if (!refreshJwt) refreshJwt = "";
      const params: RefreshParams = {
        access_jwt: accessJwt,
        refresh_jwt: refreshJwt,
      };
      const { data, status } = await RestClient.put<AuthToken>(`/auth`, params);
      // console.log("refresh, status: ", status);
      // console.log("refresh, data: ", data);
      return [status, data];
    } catch (e: unknown) {
      console.log("refresh, error: ", e);
      return GetError(e);
    }
  },
  // 사용자 계정 로그아웃
  Logout: async (id: string): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.delete<string>(`/auth/${id}`);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // OTP 등록 URL 요청
  GetOtpUrlByEmail: async (
    email: string
  ): Promise<[number, OtpUrlResponse | string]> => {
    try {
      const { data, status } = await RestClient.get<OtpUrlResponse>(
        `/users/otp/url?email=${email}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // OTP 재설정 요청
  ForgotOtp: async (p: ForgotParams): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.patch<string>(
        `/users/otp/forgot`,
        p
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  // OTP 재설정 ID를 사용하여 OTP 비밀키값을 초기화한다
  ResetOtp: async (id: string): Promise<[number, User | string]> => {
    try {
      const { data, status } = await RestClient.get<User>(
        `/users/otp/reset/${id}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  GetFieldValues: async (
    field: string
  ): Promise<[number, string | string[]]> => {
    try {
      const { data, status } = await RestClient.get<string>(
        `/users/field/${field}/values`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
};
