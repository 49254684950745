const roles = {
  dialog: {
    title: "계정 역할 설정",
    role_name: "계정 역할 명",
    role_name_precautions_title: "역할 명 입력 시 주의사항",
    role_name_precautions:
      "입력 문자는 영어 대문자/소문자, 숫자만 가능하며, 9 ~ 32자리 사이로 입력해 주세요. (특수문자 _ - 범위 내에서 선택)",
    menu_permission_tab: "메뉴 접근 권한",
    menu_permission_title: "메뉴 접근 권한 설정",
    menu_item: "항목",
    menu_read_permission: "조회 권한",
    menu_write_permission: "편집 권한",
    download_permission_tab: "다운로드 권한",
    download_permission_title: "다운로드 권한 설정",
    collection_files: "수집 파일",
    download_permission: "다운로드 권한",
    download_pe: "실행파일 다운로드",
    download_non_pe: "비-실행파일 다운로드",
    document_files: "문서 파일",
    non_document_files: "비-문서 파일",
    other_files: "기타 파일",
    log: "로그 정보",
    ok: "저장",
    cancel: "취소",
  },
  table: {
    create: "역할 생성",
    delete: "역할 삭제",
    role: "역할",
    created_at: "생성일",
    updated_at: "최근 수정일",
    creator_id: "생성자 ID",
  },
};

export default roles;
