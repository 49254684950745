const policyRoutes = [
  {
    path: "/policy/profiles",
    name: "policy-profiles",
    meta: { aclName: "policy" },
    component: () =>
      import(
        /* webpackChunckName: "policy" */ "../views/dashboard/policy/NrProfilesView.vue"
      ),
  },
  {
    path: "/policy/sensors",
    name: "policy-sensors",
    meta: { aclName: "policy" },
    component: () =>
      import(
        /* webpackChunckName: "policy" */ "../views/dashboard/policy/NrSensorsView.vue"
      ),
  },
  {
    path: "/policy/collections",
    name: "policy-collections",
    meta: { aclName: "policy" },
    component: () =>
      import(
        /* webpackChunckName: "policy" */ "../views/dashboard/policy/NrCollectionsView.vue"
      ),
  },
];

export default policyRoutes;
