import { SearchPageInfo } from "@/types/search";

export interface RoleAcl {
  name: string;
  permission: number;
}

export interface Role {
  id: string;
  name: string;
  acl: RoleAcl[];
  creator_id: string;
  created_at?: number;
  updated_at?: number;
}

export interface RoleRowsInfo {
  info: SearchPageInfo;
  rows: Role[];
}

// 권한 확인을 위한 파라미터
export interface HasPermissionParams {
  id: string;
  acl_name: string;
  permission: number;
}

// 권한 확인 응답
export interface HasPermissionResponse {
  has: boolean;
}

export const PermissionNone = 0; //권한 없음
export const PermissionRead = 1; //읽기 권한
export const PermissionWrite = 2; //쓰기 권한
