const group = {
  group_name: "Group name",
  created_at: "Group creation time",
  policy_name: "Policy profile",
  total_sensor_cnt: "Number of registered sensors",
  win_sensor_cnt: "Install OS (WIN)",
  mac_sensor_cnt: "Install OS (MAC)",
  linux_sensor_cnt: "Install OS (Linux)",
  group_setting: "Group Setting",
  group_delete: "Group Delete",
  management: "Group management",
  auto_settings: "Auto grouping settings",
  pc_host: "PC host name",
  howtogroup: "How to group",
  pc_digits: "PC host name digits",
  prefix: "Prefix-",
  digits: "number of digits",
  ip_address: "IP address range",
  affiliation_group_name: "Affiliation group name",
  add: "Add",
  delete: "Delete",
  save: "Save",
  cancel: "Cancel",
  change_group: "Edit grouping",
  change_name: "Edit group name",
  change_ip: "Edit IP address range",
  tooltip: {
    ip_group_precautions_title: "Precautions when entering the group name",
    ip_group_precautions:
      "The input characters can only be Korean, English uppercase/lowercase\n letters and numbers, and must be between 4 and 20 digits\n (special character _ - select from a range).",
  },
};

export default group;
