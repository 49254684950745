const myprofile = {
  profile_title: "Profile",
  user_title: "User",
  email: "ID",
  name: "Name",
  department: "Department",
  job_title: "Job Title",
  phone: "Phone",
  mobile: "Mobile",
  datetime_title: "Date & Time",
  time_zone: "Time Zone",
  us_hawaii: "(UTC-10:00) US/Hawaii",
  us_alaska: "(UTC-09:00) US/Alaska",
  us_pacific: "(UTC-08:00) US/Pacific",
  us_mountain: "(UTC-07:00) US/Mountain",
  us_central: "(UTC-06:00) US/Central",
  us_eastern: "(UTC-05:00) US/Eastern",
  canada_atlantic: "(UTC-04:00) Canada/Atlantic",
  utc: "(UTC) UTC",
  europe_london: "(UTC+00:00) Europe/London",
  europe_paris: "(UTC+01:00) Europe/Paris",
  asia_shanghai: "(UTC+08:00) Asia/Shanghai",
  asia_singapore: "(UTC+08:00) Asia/Singapore",
  asia_seoul: "(UTC+09:00) Asia/Seoul",
  asia_tokyo: "(UTC+09:00) Asia/Tokyo",
  australia_sydney: "(UTC+10:00) Australia/Sydney",
  datetime_format: "Date format",
  pass_api_title: "Password & API Key",
  change_password_title: "Change password",
  change_password: "Password",
  api_key_title: "API Key",
  api_key: "API Key",
  save_btn: "Save",
  change_password_btn: "Change",
  renew_btn: "Renew",
  user_modal: {
    title: "Changing User Information",
    description: "Enter your password to change your information.",
    password: "Password",
    ok_btn: "OK",
    cancel_btn: "Cancel",
  },
  api_key_modal: {
    title: "Renewing API Key",
    description: "Enter your password to your API Key.",
    sub_description:
      "When renewing an API key, the existing API key will be invalidated.",
    password: "Password",
    ok_btn: "OK",
    cancel_btn: "Cancel",
  },
  //비밀번호 변경
  change_password_description:
    "To ensure a secure password, take the following precautions",
  change_password_sub_description_1:
    "※ Passwords you've never used anywhere else",
  change_password_sub_description_2:
    "※ Passwords that have never been used before are safe.",
  password: "Current password",
  new_password: "New password",
  confirm_new_password: "Confirm new password",
  password_precautions_title: "Precautions for password",
  password_precautions:
    "Please enter a password between 9 and 32 characters including uppercase/lowercase English letters, numbers, and special characters.\nPlease select within the range of special characters {characters}",
  captcha_desciription: "Please type the characters you see in the picture.",
  captcha_label: "CAPTCHA",
  captcha_refresh_btn: "Refresh",
  captcha_ok_btn: "Verify",
  change_password_save_btn: "Save",
};

export default myprofile;
