<template>
  <router-view />
</template>
<script setup lang="ts"></script>
<style>
* {
  margin: 0;
  padding: 0;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans KR", "맑은 고딕", "Malgun Gothic", 돋움, Dotum, Arial,
    "Apple Gothic", sans-serif !important;
  background: #fff;
}

a {
  text-decoration: none;
}

img {
  border: 0;
}

li {
  list-style: none;
}

#app {
  width: 100%;
  height: 100%;
}

@import "@/assets/fonts/font.css";
@import "@/assets/css/input-common.css";
</style>
