const sensorRoutes = [
  {
    path: "/sensors",
    name: "sensors",
    meta: { aclName: "sensors" },
    component: () =>
      import(
        /* webpackChunckName: "sensors" */ "../views/dashboard/sensors/NrSensorsView.vue"
      ),
  },
  {
    path: "/sensors/detail/:id",
    name: "sensors-detail",
    meta: { aclName: "sensors" },
    component: () =>
      import(
        /* webpackChunckName: "sensors" */ "../components/dashboard/Sensors/NrSensorDetail.vue"
      ),
  },
];

export default sensorRoutes;
