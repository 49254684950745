import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { useAuthStore } from "@/store/auth";
import { useMenuStore } from "@/store/menu";
import {
  HasPermissionParams,
  HasPermissionResponse,
  PermissionRead,
} from "@/types/role";
import { RoleApi } from "@/api/roleApi";
import { UserApi } from "@/api/userApi";

//이동하려는 페이지에 접근권한이 존재하는지 확인한다
const hasPermission = async (
  to: RouteLocationNormalized
): Promise<[boolean, string]> => {
  const authStore = useAuthStore();
  const params: HasPermissionParams = {
    id: authStore.role_id,
    acl_name: to.meta.aclName as string,
    permission: PermissionRead,
  };
  const [status, data] = await RoleApi.HasPermission(params);
  //성공인 경우
  if (status === 200) {
    const res = data as HasPermissionResponse;
    if (res.has) {
      return [true, ""];
    } else {
      return [false, "접근 권한이 없습니다."];
    }
  }

  let msg = "";
  if (status === 401) {
    authStore.clearAuthToken();
    msg = "인증 정보가 만료되었습니다.";
  } else if (status === 404) {
    msg = "권한 정보가 존재하지않습니다.";
  } else {
    msg =
      "내부 오류가 발생하였습니다(" +
      status +
      ").\n잠시 후 다시 시도해주시기 바랍니다.";
  }
  return [false, msg];
};

export const beforeEachGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.meta.authRequired) {
    const authStore = useAuthStore();
    if (!authStore.accessJwt) {
      next({ name: "login" });
    } else {
      //접근 권한이 존재하는지 확인한다
      const menuStore = useMenuStore();
      const [has, msg] = await hasPermission(to);
      if (has) {
        next();
        //이동한 페이지에 해당하는 메뉴를 선택된 상태로 표시
        menuStore.selectMenuWithRoutePath(to.path);
      } else {
        alert(msg);
        next(false);
      }
    }
  } else {
    if (to.name == "login" || to.name == "forgot" || to.name == "reset") {
      // 이미 로그인되어 있는 상태인 경우 현재 페이지에 머무르게한다.
      const authStore = useAuthStore();
      if (authStore.accessJwt) {
        if (from.name) {
          next({ name: from.name });
        } else {
          next({ name: "main" });
        }
        return;
      }
    } else if (to.name == "admin") {
      // 이미 관리자 계정이 등록되어 있는 경우 현재 페이지에 머무르게한다.
      const [status] = await UserApi.IsAdminRegistered();
      if (status != 404) {
        next({ name: "login" });
      } else {
        next();
      }
      return;
    }
    next();
  }
};
