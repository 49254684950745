const forgotpassword = {
  title: "비밀번호 찾기",
  description:
    "이 작업을 수행하려면 메일 인증이 필요합니다.<br>메일 주소를 입력해 주세요.<br>받은 편지함을 확인하시고 제공된 지시에 따라 주세요.",
  email: "이메일",
  email_placeholder: "이메일 주소",
  ok_btn: "확인",
};

export default forgotpassword;
