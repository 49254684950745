const login = {
  settings: "로그인 설정",
  method: "로그인 방법",
  method_0: "아이디+비밀번호",
  method_1: "아이디+비밀번호+OTP",
  method_2: "SAML 2.0",
  lockout: "계정 잠금",
  enabled: "사용함",
  disabled: "사용안함",
  times: "회",
  try_count_range: "[ 3회 ~ 100회 ]",
  lockout_duration: "계정 잠금 시간",
  lockout_duration_range: "[ 1분 ~ 60분 ]",
  auto_logout: "자동 로그아웃",
  auto_logout_range: "[ 5분 ~ 60분 ]",
  password_settings: "비밀번호 설정",
  check_previous_password: "이전 비밀번호 검사",
  confirmation_mail_duration: "비밀번호 변경 인증 메일 유효시간",
  minutes: "분",
  hours: "시간",
  confirmation_mail_duration_range:
    "[ 최대 1일 이내 / 24시간 이내 / 1,440분 이내 ]",
};

export default login;
