const resetpassword = {
  title: "비밀번호 재설정",
  description: "새로운 비밀번호를 입력해 주세요.",
  password: "새 비밀번호",
  confirm_password: "새 비밀번호 확인",
  password_precautions_title: "비밀번호 입력시 주의 사항",
  password_precautions:
    "비밀번호는 영어 대문자/소문자, 숫자, 특수문자를 모두 포함하여 9 ~ 32자리 사이로 입력해 주세요.\n(특수문자 {characters} 범위 내에서 선택)",
  reset_btn: "재설정",
};

export default resetpassword;
