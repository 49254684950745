const policy = {
  dialog: {
    ok: "Save",
    cancel: "Cancel",
    trayicon: "Tray icon",
    show: "Show",
    hide: "Hide",
    traymenu: "Tray menu",
    traymenu_password: "Tray menu activation password",
    sensor_password: "Sensor erase password",
    server_cycle: "Server check cycle",
    health_cycle: "Health check cycle",
    minute_interval: "Minute interval (1 minute ~ 60 minutes)",
    server_setting: "Management server settings",
    server_address: "Management server address",
    add: "Add",
    delete: "Delete",
    maximum: "(Maximum allowed number of registrations: 4)",
    patch_setting: "Patch settings",
    patch_criteria: "Patch criteria",
    no_updates: "No automatic updates",
    latest_version: "Sensor latest version",
    specific_version: "Sensor specific version",
    patch_address: "Patch server address",
    patch_cycle: "Patch cycle",
    everyday: "Everyday",
    from_time: "From time",
    until_time: "Until time",
    someday: "Specific day",
    su: "Su",
    mo: "Mo",
    tu: "Tu",
    we: "We",
    th: "Th",
    fr: "Fr",
    sa: "Sa",
    day: "Day",
    week: "Week",
    minutes: "Minutes",
    hours: "Hours",
    time: "Time",
    cycle: "Cycle",
    range: "Range",
    select: "Select",
    with: "with",
    in: "in",
    collection_target: "Collection target",
    pe_file: "Executable file",
    non_pe_file: "Non-executable files",
    file_limit_size: "Collected file limit size",
    collection_period: "Collection period classification",
    collection_cycle: "Collection cycle",
    within_300: "[ Within 300 minutes / within 5 hours ]",
  },
  table: {
    profile: "Profile management",
    profile_create: "Profile creation",
    profile_delete: "Delete profile",
    policy_profile: "Policy name",
    created_at: "Created",
    collecion_policy: "Collection policy name",
    sensor_policy: "Sensor name",
    registration: "Registration date",
    updated_at: "Updated",
    profile_setting: "Profile settings",
    policy_profile_setting: "Policy profile settings",
    comment: "Comment",
    information: "Applicable policy information",
    create: "Create policy",
    sensor_control: "Sensor control policy",
    add_policy: "Add policy",
    delete_policy: "Delete policy",
    creator_id: "ID",
    registrant: "Registrant",
    sensor_control_settiing: "Sensor control setting",
    colletion_policy: "Collection policy",
    colletion_policy_setting: "Collection policy settings",
    collection_policy: "Collection name",
    collection_setting: "Collection Settings",
    only_collect: "Collect only collect files",
    only_executable: "Collect only executable files",
    only_non_executable: "Collect only non-executable files",
    no_limit: "No limit",
    limit: "Limit",
  },
  tooltip: {
    profile_precautions_title: "Precautions when entering policy profile name",
    sensor_precautions_title:
      "Precautions when entering the sensor control policy name",
    collection_precautions_title:
      "Precautions when entering the collection policy name",
    sensor_precautions:
      "The input characters can only be English uppercase/lowercase\n letters and numbers, and must be between 9 and 32 digits\n (special character _ - select from a range).",
    description_precautions_title: "Notes when entering comments",
    sensor_description: "Enter 50 characters or less.",
  },
};

export default policy;
