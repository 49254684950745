const collections = {
  table: {
    download_copy: "Download and Copy",
    download: "Download selected files",
    copy: "Copy Hash",
    id: "File Name",
    created_at: "Created",
    format: "Format",
    extension: "Extension",
    pre_defined: "Pre-defined",
    version: "Version",
    digital_signature: "Signature",
    paths: "Paths",
    sensor_id: "Sensor Id",
    sensor_no: "Sensor Number",
    action: "Action",
    executable: "executable",
    non_executable: "non_executable",
  },
  detail: {
    type: "Collection Location",
    created_at: "Collection Time",
    updated_at: "Collection Transfer Time",
    md5: "MD5",
    sha1: "SHA-1",
    sha256: "SHA-256",
    ssdeep: "SSDEEP",
    type_sensor: "Sensor",
    type_console: "Console",
    type_server: "Server",
    generalinfo: "General information",
    os_type: "OS Type",
    architecture: "Architecture",
    bit: "Bit",
    extension: "File extension",
    format: "File Format",
    pre_defined: "Pre-defined",
    mime_type: "MIME Type",
    size: "File size",
    path: "Observation path",
    meta_version: "File version information",
    description: "File description",
    version: "File Version",
    original_name: "Original Name",
    internal_name: "Internal Name",
    company_name: "Company Name",
    product_name: "Product Name",
    meta_info: "Digital signature information",
    digital_signature: "Signed or not",
    publisher: "Publisher",
    signature_time: "Signature time",
    program: "Program Name",
    issuer: "Issuer",
    subject: "Subject",
  },
};

export default collections;
