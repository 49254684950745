const dashboard = {
  sensor_installation_status: "Sensor installation status",
  group_installation_status: "by Group",
  os_installation_status: "by OS",
  format_collection_status: "Format collection status",
  executable: "Executable file",
  non_executable: "Non-executable files",
  binary_collection_status: "Binary collection status",
  daily: "Daily",
  monthly: "Monthly",
  month: "Month",
  health_check_problem_sensor: "health check problem sensor",
  long_unconnected_sensor: "long unconnected sensor",
  policy_not_received_sensor: "Policy not received/applied sensor",
  non_updatable_sensor: "Non-updatable sensor",
  sensor_status: "Sensor status",
  collection_status: "Collection status",
  collection_list: "Collection list",
};

export default dashboard;
