const setpassword = {
  title: "Set your password",
  description: "Please enter a password.",
  password: "Password",
  confirm_password: "Confirm password",
  password_precautions_title: "Precautions for password",
  password_precautions:
    "Please enter a password between 9 and 32 characters including uppercase/lowercase English letters, numbers, and special characters.\nPlease select within the range of special characters {characters}",
  set_btn: "Set",
};

export default setpassword;
