const dashboard = {
  sensor_installation_status: "센서 설치 현황",
  group_installation_status: "그룹별 설치 현황",
  os_installation_status: "OS별 설치 현황",
  format_collection_status: "포맷별 수집 현황",
  executable: "실행 파일",
  non_executable: "비-실행 파일",
  binary_collection_status: "바이너리 수집 현황",
  daily: "일별",
  monthly: "월별",
  month: "월",
  health_check_problem_sensor: "헬스체크 문제 센서",
  long_unconnected_sensor: "장기 미 접속 센서",
  policy_not_received_sensor: "정책 미 수령/적용 센서",
  non_updatable_sensor: "업데이트 불가 센서",
  sensor_status: "센서 현황",
  collection_status: "수집 현황",
  collection_list: "수집 목록",
};

export default dashboard;
