import admin from "./admin";
import alert from "./alert";
import breadcrumb from "./breadcrumb";
import common from "./common";
import collections from "./collections";
import datatable from "./datatable";
import forgotpassword from "./forgotpassword";
import header from "./header";
import login from "./login";
import menu from "./menu";
import myprofile from "./myprofile";
import resetpassword from "./resetpassword";
import roles from "./roles";
import search from "./search";
import sensors from "./sensors";
import setpassword from "./setpassword";
import settings from "./settings/settings";
import users from "./users";
import validations from "./validations";
import policy from "./policy";
import packages from "./packages";
import audit from "./audit";
import dashboard from "./dashboard";

const enUS = {
  admin,
  alert,
  audit,
  breadcrumb,
  common,
  collections,
  dashboard,
  datatable,
  forgotpassword,
  header,
  login,
  menu,
  myprofile,
  policy,
  packages,
  resetpassword,
  roles,
  search,
  sensors,
  setpassword,
  settings,
  users,
  validations,
};

export default enUS;
