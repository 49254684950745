const policy = {
  dialog: {
    ok: "저장",
    cancel: "취소",
    trayicon: "트레이 아이콘",
    hide: "숨김",
    show: "보이기",
    traymenu: "트레이 메뉴",
    traymenu_password: "트레이 메뉴 활성화 암호",
    sensor_password: "센서 삭제 암호",
    server_cycle: "서버 체크 주기",
    health_cycle: "헬스 체크 주기",
    minute_interval: "분 간격 (1분 ~ 60분)",
    server_setting: "관리서버 설정",
    server_address: "관리서버 주소",
    add: "추가",
    delete: "삭제",
    maximum: "(최대 허용 등록 개수: 4개)",
    patch_setting: "패치 설정",
    patch_criteria: "패치 기준",
    no_updates: "자동 업데이트 안함",
    latest_version: "센서 최신 버전",
    specific_version: "센서 특정 버전",
    patch_address: "패치 서버 주소",
    patch_cycle: "패치 주기",
    everyday: "매일",
    from_time: "시 ~",
    until_time: "시 까지",
    someday: "특정일",
    su: "일",
    mo: "월",
    tu: "화",
    we: "수",
    th: "목",
    fr: "금",
    sa: "토",
    day: "일",
    week: "주",
    minutes: "분",
    hours: "시",
    time: "시간",
    cycle: "주기",
    range: "범위",
    select: "선택",
    with: "내",
    in: "에",
    collection_target: "수집 대상",
    pe_file: "실행 파일",
    non_pe_file: "비-실행 파일",
    file_limit_size: "수집 파일 제한 크기",
    collection_period: "수집 기간 구분",
    collection_cycle: "수집 주기",
    within_300: "[ 300분 이내 / 5시간 이내 ]",
  },
  table: {
    profile: "프로파일 관리",
    profile_create: "프로파일 생성",
    profile_delete: "프로파일 삭제",
    policy_profile: "정책 프로파일명",
    created_at: "정책 등록일",
    collecion_policy: "수집 정책명",
    sensor_policy: "센서제어 정책명",
    registration: "등록일",
    updated_at: "최종 수정일",
    profile_setting: "프로파일 설정",
    policy_profile_setting: "정책 프로파일 설정",
    comment: "추가 설명",
    information: "적용 정책 정보",
    create: "정책 생성",
    sensor_control: "센서제어 정책",
    add_policy: "정책 추가",
    delete_policy: "정책 삭제",
    creator_id: "생성자 아이디",
    registrant: "생성자",
    sensor_control_settiing: "센서제어 설정",
    colletion_policy: "수집 정책",
    colletion_policy_setting: "수집 정책 설정",
    collection_policy: "파일수집 정책명",
    collection_setting: "수집 설정",
    only_collect: "수집 파일 만 수집",
    only_executable: "실행 파일 만 수집",
    only_non_executable: "비-실행 파일 만 수집",
    no_limit: "제한 없음",
    limit: "제한",
  },
  tooltip: {
    profile_precautions_title: "정책 프로파일명 입력시 주의사항",
    sensor_precautions_title: "센서제어 정책명 입력시 주의사항",
    collection_precautions_title: "수집 정책명 입력시 주의사항",
    sensor_precautions:
      "입력 문자는 영어 대문자/소문자, 숫자만 가능하며,\n 9 ~ 32자리 사이로 입력해 주세요.\n (특수문자 _ - 범위 내에서 선택)",
    description_precautions_title: "추가 설명 입력 시 주의사항",
    sensor_description: "입력 문자는 50자 이하로 입력해 주세요.",
  },
};

export default policy;
