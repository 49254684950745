import { RestClient, GetError } from "@/api/restclient";
import { SearchParam, RowsInfo } from "@/types/search";
import { Role, HasPermissionParams, HasPermissionResponse } from "@/types/role";

export const RoleApi = {
  Search: async <T>(
    sp: SearchParam
  ): Promise<[number, string | RowsInfo<T>]> => {
    try {
      const { data, status } = await RestClient.post<RowsInfo<T>>(
        `/roles/search`,
        sp
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  Add: async (r: Role): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.post<string>(`/roles`, r);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  Update: async (r: Role): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.put(`/roles/${r.id}`, r);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  Delete: async (id: string): Promise<[number, string]> => {
    try {
      const { data, status } = await RestClient.delete(`/roles/${id}`);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  HasPermission: async (
    params: HasPermissionParams
  ): Promise<[number, HasPermissionResponse | string]> => {
    try {
      const { data, status } = await RestClient.post<HasPermissionResponse>(
        `/roles/${params.id}/acl`,
        params
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  GetFieldValues: async (
    field: string
  ): Promise<[number, string | string[]]> => {
    try {
      const { data, status } = await RestClient.get<string>(
        `/roles/field/${field}/values`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
};
