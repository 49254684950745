const menu = {
  dashboard: "대시보드",
  sensors: "센서 현황",
  sensor_list: "센서 목록",
  collections: "수집 현황",
  collection_list: "수집 목록",
  users: "사용자 관리",
  roles: "역할 관리",
  accounts: "계정 관리",
  policy: "정책 관리",
  policy_profile: "프로파일 관리",
  sensor_policy: "센서제어 정책",
  file_collection_policy: "파일 수집 정책",
  logs: "로그 관리",
  console_connection_log: "관리콘솔 접속 로그",
  console_audit_log: "관리콘솔 감사 로그",
  sensor_log: "센서 로그",
  file_download_log: "수집 파일 다운로드 로그",
  packages: "설치 패키지 관리",
  base_packages: "기본 셋업본 관리",
  install_packages: "설치 패키지 관리",
  packages_builder: "설치 패키지 빌더",
  settings: "환경 설정",
  system_settings: "시스템 관리",
  login_settings: "로그인 관리",
  group_settings: "그룹 관리",
  license_settings: "라이선스 관리",
};

export default menu;
