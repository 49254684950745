const packagesRoutes = [
  {
    path: "/packages/bases",
    name: "packages-bases",
    meta: { aclName: "packages" },
    component: () =>
      import(
        /* webpackChunckName: "packages" */ "../views/dashboard/packages/NrBasesView.vue"
      ),
  },
  {
    path: "/packages",
    name: "packages",
    meta: { aclName: "packages" },
    component: () =>
      import(
        /* webpackChunckName: "packages" */ "../views/dashboard/packages/NrPackagesView.vue"
      ),
  },
  {
    path: "/packages/builder",
    name: "packages-builder-set",
    component: () =>
      import(
        /* webpackChunckName: "packages" */ "../views/dashboard/packages/NrBuilderView.vue"
      ),
  },
  {
    path: "/packages/builder/:id",
    name: "packages-builder",
    component: () =>
      import(
        /* webpackChunckName: "packages" */ "../views/dashboard/packages/NrBuilderView.vue"
      ),
  },
];

export default packagesRoutes;
