const packages = {
  manage_installation: "Manage installation packages",
  manage_base: "Manage Basic Setups",
  list_packages: "List of installation packages",
  package_builder: "Install Package Builder",
  default_setup: "Default setup file name",
  registration_date: "Registration date",
  delete_installer: "Delete Installer package installation",
  table: {
    add_file: "Add default setup file",
    delete_file: "Delete default setup file",
    sensor_version: "Sensor version",
    platform: "Platform",
    architecture: "Architecture",
    registration: "Registration date",
    name: "File name",
    token_id: "Token ID",
    token_expired: "Token Expiration period",
    token_last: "Token remaining validity period",
    token_status: "Token status",
    file_hash: "Fle hash information",
    package_delete: "Delete installation package",
    download: "Download",
  },
  package: {
    expiration: "Expiration",
    valid: "Valid",
    day: "day",
    hour: "hour",
    no_expiration_date: "No expiration date",
  },
  builder: {
    file_builder: "Installation file builder",
    enter_code:
      "Enter the company code (Company ID) issued in advance and the domain information used.",
    company_id: "Company ID",
    domain: "Domain information",
    enter_information:
      "Please enter the configuration information below for MINOSS Sensor Builder.",
    installation_packages: "Installation package file name",
    server: "Management server information (IP/URL)",
    default_version: "Default Setup File Version",
    os_type: "OS type",
    windows: "Windows",
    name: "File Name",
    installation_path: "Installation path",
    installation_screen: "Installation screen",
    hide: "Hide",
    activate: "Activate",
    installation_tray: "Installation tray",
    tray_menu: "Tray Menu",
    password: "Sensor delete password",
    version_lower:
      "※ If the sensor is already installed and the installed version is lower, delete it and proceed with the installation.",
    token: "Token creation",
    security:
      "※ For security, it is recommended to set the validity period short, and it starts with creation.",
    token_period: "Token validity period selection",
    period: "Select period",
    days_7: "7 days",
    set_day: "[ 7 days or more and 365 days or less can be set ]",
    days_30: "30 days",
    days_90: "90 days",
    year_1: "1 year",
    no_expire: "No expiration date",
    direct: "Direct input",
    day: "day",
    token_scope: "Token application scope",
    all_sensor: "All sensor versions",
    new_sensor: "Apply behavior when creating a new sensor",
    registered_version: "Existing registered version",
    create: "Create",
    continue: "Continue to create",
  },
};

export default packages;
