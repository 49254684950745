const collections = {
  table: {
    download_copy: "다운로드 및 복사",
    download: "선택 파일 다운로드",
    copy: "선택 파일 해쉬 클립보드 복사",
    id: "파일명",
    created_at: "수집 시간",
    format: "파일 포맷",
    extension: "확장자",
    pre_defined: "사전 정의",
    version: "파일 버전",
    digital_signature: "서명 정보",
    paths: "수집 경로",
    sensor_id: "수집된 센서 번호",
    sensor_no: "수집된 센서 번호",
    action: "Action",
    executable: "실행파일",
    non_executable: "비-실행파일",
  },
  detail: {
    type: "수집 위치",
    created_at: "수집 시간",
    updated_at: "수집 전송 시간",
    md5: "MD5",
    sha1: "SHA-1",
    sha256: "SHA-256",
    ssdeep: "SSDEEP",
    type_sensor: "센서",
    type_console: "콘솔",
    type_server: "서버",
    generalinfo: "일반 정보",
    os_type: "OS 타입",
    architecture: "아키텍처",
    bit: "비트",
    extension: "파일 확장자",
    format: "파일 포맷",
    pre_defined: "사전 정의",
    mime_type: "MIME 타입",
    size: "파일 크기",
    path: "관찰 경로",
    meta_version: "파일 버전 정보",
    description: "파일 설명",
    version: "파일 버전",
    original_name: "Original Name",
    internal_name: "Internal Name",
    company_name: "Company Name",
    product_name: "Product Name",
    meta_info: "디지털 서명 정보",
    digital_signature: "서명 여부",
    publisher: "Publisher",
    signature_time: "서명 시간",
    program: "프로그램 이름",
    issuer: "Issuer",
    subject: "Subject",
  },
};

export default collections;
