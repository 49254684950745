const packages = {
  manage_installation: "설치 패키지 관리",
  manage_base: "기본 셋업본 관리",
  list_packages: "설치 패키지 목록",
  package_builder: "설치 패키지 빌더",
  default_setup: "기본 셋업 파일명",
  registration_date: "셋업 파일 등록일",
  delete_installer: "Installer package 설치본 삭제",
  table: {
    add_file: "기본 셋업 파일 추가",
    delete_file: "기본 셋업 파일 삭제",
    sensor_version: "센서 버전",
    platform: "플랫폼",
    architecture: "아키텍처",
    registration: "파일 등록일",
    name: "파일명",
    token_id: "Token 아이디",
    token_expired: "Token 유효기간",
    token_last: "Token 남은 유효기간",
    token_status: "Token 상태",
    file_hash: "파일 해시 정보",
    package_delete: "설치 패키지 삭제",
    download: "다운로드",
  },
  package: {
    expiration: "만료",
    valid: "유효",
    day: "일",
    hour: "시간",
    no_expiration_date: "유효기간 없음",
  },
  builder: {
    file_builder: "설치파일 빌더",
    enter_code:
      "사전에 발급 받은 기업 코드(Customer ID)와 사용 도메인 정보를 입력해 주세요.",
    company_id: "기업 코드",
    domain: "도메인 정보",
    enter_information:
      "MINOSS 센서 설치파일 빌더를 위하여 아래 설정 정보를 입력해 주세요.",
    installation_packages: "설치 패키지 파일명",
    server: "관리서버 정보(IP/URL)",
    default_version: "기본 셋업 파일 버전",
    os_type: "OS 종류",
    windows: "Windows",
    name: "파일명",
    installation_path: "설치 경로",
    installation_screen: "설치 화면",
    hide: "숨김",
    activate: "활성화",
    installation_tray: "센서 트레이 아이콘",
    tray_menu: "트레이 메뉴",
    password: "센서 삭제 비밀번호",
    version_lower:
      "※ 이미 센서가 설치된 경우 설치된 버전이 낮으면 삭제 후 설치를 진행합니다.",
    token: "Token 생성",
    security:
      "※ 보안을 위해서는 유효기간을 짧게 설정하는 것을 권장하며, 생성일로 시작합니다.",
    token_period: "Token 유효기간 선택",
    period: "기간 선택",
    days_7: "7일",
    days_30: "30일",
    days_90: "90일",
    year_1: "1년",
    no_expire: "유효기간 없음",
    direct: "직접 입력",
    day: "일",
    set_day: "[ 7일 이상 365일 이하 설정 가능 ]",
    token_scope: "Token 적용 범위",
    all_sensor: "모든 센서 버전",
    new_sensor: "신규 센서 생성 시 자동 적용",
    registered_version: "기존 등록 버전",
    create: "생성",
    continue: "계속 생성",
  },
};

export default packages;
