const login = {
  title: "MINOSS Login",
  email: "Email",
  email_placeholder: "Email address",
  password: "Password",
  password_placeholder: "Password",
  save_email: "Save email",
  forgot_password: "Forgot password",
  login: "Login",
  otp_title: "OTP Registration",
  otp_description:
    "Scan the QR code in the Google OTP app and enter the 6-digit number you received.",
  otp_code: "OTP code",
  otp_code_placeholder: "6-digit number",
  otp_registration: "Registration",
  otp_auth_title: "OTP Authentication",
  otp_auth_description: "Enter your OTP code to complete the Login.",
  cancel: "Cancel",
  forgot_otp: "Forgot OTP",
  otp_auth: "Authentication",
};

export default login;
