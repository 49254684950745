const license = {
  license_management: "라이선스 관리",
  registration_license: "등록 라이선스",
  change: "변경",
  delete: "삭제",
  validation: "유효성 검사",
  product_name: "제품명",
  version: "버전",
  customer_name: "고객명",
  license_remaining_days: "라이선스 잔여일",
  save: "저장",
};

export default license;
