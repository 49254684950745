const login = {
  title: "MINOSS 로그인",
  email: "이메일",
  email_placeholder: "이메일 주소",
  password: "비밀번호",
  password_placeholder: "비밀번호",
  save_email: "이메일 저장",
  forgot_password: "비밀번호 찾기",
  login: "로그인",
  otp_title: "OTP 등록",
  otp_description:
    "구글 OTP앱에서 QR코드를 스캔한 후 발급받은 6자리 숫자를 입력해주세요.",
  otp_code: "OTP 코드",
  otp_code_placeholder: "6자리 코드",
  otp_registration: "등록",
  otp_auth_title: "OTP 인증",
  otp_auth_description: "OTP 코드를 입력하여 로그인을 완료하십시오.",
  cancel: "취소",
  forgot_otp: "OTP 재설정",
  otp_auth: "인증",
};

export default login;
