const myprofileRoutes = [
  {
    path: "/myprofile",
    name: "myprofile",
    component: () =>
      import(
        /* webpackChunckName: "myprofile" */ "../views/dashboard/myprofile/NrMyProfileView.vue"
      ),
  },
  {
    path: "/myprofile/password",
    name: "myprofile-password",
    component: () =>
      import(
        /* webpackChunckName: "myprofile-password" */ "../views/dashboard/myprofile/NrChangePasswordView.vue"
      ),
  },
];

export default myprofileRoutes;
