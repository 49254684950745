const roles = {
  dialog: {
    title: "Set Account Role",
    role_name: "Role Name",
    role_name_precautions_title: "Precautions for Role Name",
    role_name_precautions:
      "The input characters can only be English uppercase/lowercase letters and numbers, and must be between 9 and 32 digits (special character _ - select from a range).",
    menu_permission_tab: "Menu Permissions",
    menu_permission_title: "Set Menu Permissions",
    menu_item: "Menu",
    menu_read_permission: "View Permissions",
    menu_write_permission: "Edit Permissions",
    download_permission_tab: "Download Permissions",
    download_permission_title: "Set Download Permission",
    collection_files: "Collection Files",
    download_permission: "Download Permissions",
    download_pe: "Excutable Files",
    download_non_pe: "Non-Excutable Files",
    document_files: "Document Files",
    non_document_files: "Non-Document Files",
    other_files: "Other Files",
    log: "Logs",
    ok: "Save",
    cancel: "Cancel",
  },
  table: {
    create: "Add",
    delete: "Delete",
    role: "Role",
    created_at: "Created",
    updated_at: "Updated",
    creator_id: "Creator ID",
  },
};

export default roles;
