const admin = {
  precautions_title: "사용자 정보 등록 시 주의사항",
  precautions:
    "이메일은 사용 중인 이메일 주소를 입력해 주세요.\n이름은 한글 또는 영문으로 입력해 주세요.\n비밀번호는 영어 대문자/소문자, 숫자, 특수문자를 모두 포함하여 9 ~ 32자리 사이로 입력해 주세요.\n(특수문자 {characters} 범위 내에서 선택)",
  title: "Admin 등록",
  email: "이메일",
  name: "이름",
  phone: "연락처",
  password: "비밀번호",
  confirm_password: "비밀번호 확인",
  password_precautions_title: "비밀번호 입력시 주의 사항",
  password_precautions:
    "비밀번호는 영어 대문자/소문자, 숫자, 특수문자를 모두 포함하여 9 ~ 32자리 사이로 입력해 주세요.\n(특수문자 {characters} 범위 내에서 선택)",
  message: "등록한 Admin 정보를 사용하여 로그인할 수 있습니다.",
  registration: "등록",
  cancel: "취소",
};

export default admin;
