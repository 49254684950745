const users = {
  dialog: {
    title: "Account",
    info: "Account Information",
    id: "ID",
    role: "Role",
    name: "Name",
    department: "Department",
    job_title: "Job Title",
    phone: "Phone",
    mobile: "Mobile",
    console_allowed_ip: "Allowed access IPs",
    download_allowed_ip: "Allowed downloads IPs",
    language: "Invitation email language",
    ko_kr: "Korean",
    en_us: "English",
    enabled: "Enabled",
    disabled: "Disabled",
    add_btn: "Add",
    delete_btn: "Delete",
    ok_btn: "Save",
    cancel_btn: "Cancel",
  },
  table: {
    create: "Add",
    delete: "Delete",
    email: "ID",
    name: "Name",
    role_id: "Role",
    created_at: "Created",
    status: "Status",
    active: "Active",
    inactive: "Inactive",
    resend_email: "Resending an email",
  },
};

export default users;
