const group = {
  group_name: "그룹명",
  created_at: "그룹 생성 시간",
  policy_name: "정책 프로파일",
  total_sensor_cnt: "등록 센서 수",
  win_sensor_cnt: "설치 OS (WIN)",
  mac_sensor_cnt: "설치 OS (MAC)",
  linux_sensor_cnt: "설치 OS (Linux)",
  group_setting: "그룹 설정",
  group_delete: "그룹 삭제",
  management: "그룹 관리",
  auto_settings: "자동 그룹화 설정",
  pc_host: "PC 호스트명",
  howtogroup: "그룹화 방법",
  pc_digits: "PC 호스트명 자리 수",
  prefix: "Prefix-",
  digits: "자리 수",
  ip_address: "IP 주소 범위",
  affiliation_group_name: "소속 그룹명",
  add: "추가",
  delete: "삭제",
  save: "저장",
  cancel: "취소",
  change_group: "그룹화 수정",
  change_name: "그룹명 수정",
  change_ip: "IP 범위 수정",
  tooltip: {
    ip_group_precautions_title: "소속 그룹명 입력시 주의사항",
    ip_group_precautions:
      "입력 문자는 한글, 영어 대문자/소문자, 숫자만 가능하며,\n 4 ~ 20자리 사이로 입력해 주세요.\n (특수문자 _ - 범위 내에서 선택)",
  },
};

export default group;
