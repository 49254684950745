const collectionsRoutes = [
  {
    path: "/collections",
    name: "collections",
    meta: { aclName: "collections" },
    component: () =>
      import(
        /* webpackChunckName: "collections" */ "../views/dashboard/collections/NrCollectionsView.vue"
      ),
  },
  {
    path: "/collections/file-details/:id",
    name: "collection-file-details",
    meta: { aclName: "collections" },
    component: () =>
      import(
        /* webpackChunckName: "collections" */ "../views/dashboard/collections/NrCollectionsFileDetailsView.vue"
      ),
  },
];

export default collectionsRoutes;
