const usersRoutes = [
  {
    path: "/users/roles",
    name: "users-roles",
    meta: { aclName: "users" },
    component: () =>
      import(
        /* webpackChunckName: "users" */ "../views/dashboard/users/NrRolesView.vue"
      ),
  },
  {
    path: "/users/users",
    name: "users-users",
    meta: { aclName: "users" },
    component: () =>
      import(
        /* webpackChunckName: "users" */ "../views/dashboard/users/NrUsersView.vue"
      ),
  },
];

export default usersRoutes;
