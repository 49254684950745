const audit = {
  log_management: "로그 관리",
  access_log: "관리콘솔 접속 로그",
  sensor_log: "센서 로그",
  audit_log: "관리콘솔 감사 로그",
  download_log: "수집 파일 다운로드 로그",
  download: "다운로드",
  access_time: "접속 시간",
  id: "계정 아이디",
  name: "계정 이름",
  role: "역할",
  ip: "접속 IP",
  type: "작업 유형",
  target: "작업 대상",
  status: "작업 상태",
  contents: "내용",
  header: "헤더 필드",
  connection_log: {
    role: "역할",
    user: "사용자",
    console_connection: "콘솔 접속",
    policy: "정책 관리",
    audit: "감사로그",
    settings: "환경설정",
    package: "패키지",
    file: "파일",
    sensor_manage: "센서 관리",
    sensor: "센서",
    datastats: "데이터 통계",
    login: "로그인",
    logout: "로그아웃",
    id_not_active: "id 비활성화 상태",
    id_not_exist: "id 존재하지 않음",
    email_exists: "이메일 존재",
    email_not_exist: "이메일 존재하지 않음",
    password_mismatch: "패스워드 불일치",
    password_equal: "기존, 신규 비밀번호 동일",
    expired_id: "등록 id 만료",
    register_id_not_exist: "등록 id 존재하지 않음",
    expired_reset_id: "리셋 id 만료",
    reset_not_exist: "리셋 id 존재하지 않음",
    not_allowed_ip: "허용되지 않은 ip",
    invalid_query: "유효하지 않은 쿼리",
    invalid_parameters: "유효하지 않은 파라미터",
    permission_denied: "권한 없음",
    account_locked: "계정 잠금 상태",
    invalid_email: "유효하지 않은 이메일",
    invalid_id: "유효하지 않은 id",
    invalid_ip: "유효하지 않은 ip",
    invalid_password_format: "유효하지 않은 패스워드 형식",
    invalid_password_length: "유효하지 않은 패스워드 길이",
    invalid_token: "유효하지 않은 토큰",
    expired_token: "만료된 토큰",
    token_not_exists: "토큰 존재하지 않음",
    role_already: "역할명 존재",
    internal_error: "내부 오류",
    1: "성공",
    2: "실패",
  },
  console_log: {
    user_info_update: "사용자 정보 업데이트",
    password_change: "비밀번호 변경",
    apikey_change: "API KEY 변경",
    status_change: "사용자 상태 수정",
    user_delete: "사용자 삭제",
    user_activate: "사용자 활성화",
    password_set: "비밀번호 설정",
    password_reset: "비밀번호 재설정",
    password_forgot: "비밀번호 초기화",
    otp_set: "OTP 설정",
    otp_reset: "OTP 재설정",
    otp_forgot: "OTP 초기화",
    signup: "회원가입",
    role_create: "역할 생성",
    role_delete: "역할 삭제",
    role_update: "역할 수정",
    log_download: "로그 파일 다운로드",
    system_settings_set: "시스템 관리 정보 설정",
    group_settings_set: "그룹 관리 정보 설정",
    login_settings_set: "로그인 관리 정보 설정",
    group_info_add: "그룹 정보 추가",
    group_info_delete: "그룹 정보 삭제",
    ip_group_info_update: "IP 그룹 정보 수정",
    server_connection_check: "서버 계정 인증 테스트",
    policy_profile_add: "정책 프로파일 추가",
    policy_profile_delete: "정책 프로파일 삭제",
    policy_profile_update: "정책 프로파일 수정",
    policy_sensor_add: "센서제어 정책 추가",
    policy_sensor_delete: "센서제어 정책 삭제",
    policy_sensor_update: "센서제어 정책 수정",
    policy_collection_add: "파일수집 정책 추가",
    policy_collection_delete: "파일수집 정책 삭제",
    policy_collection_update: "파일수집 정책 수정",
    base_package_add: "기본 셋업본 추가",
    base_package_delete: "기본 셋업본 삭제",
    sensor_package_add: "설치 패키지 추가",
    sensor_package_delete: "설치 패키지 삭제",
    sensor_package_expired_time_reset: "설치 패키지 토큰 유효기간 리셋",
    sensor_package_download: "센서 패키지 다운로드",
    policy_assign_to_group: "그룹에 정책 할당",
    policy_assign_to_sensor: "센서에 정책 할당",
    file_upload: "파일 업로드",
    file_delete: "파일 삭제",
    long_term_absense_sensor_patch_count: "장기 미접속 센서수",
  },
  sensor: {
    sensor_no: "센서 번호",
    sensor_group: "센서 그룹",
    sensor: "센서",
    sensor_command: "명령",
    sensor_upsert: "센서 정보 추가 / 업데이트",
    sensor_create: "센서 정보 추가",
    sensor_update: "센서 정보 업데이트",
    sensor_delete: "센서 삭제",
    sensor_update_status: "센서 상태 업데이트",
    sensor_update_systeminfo: "센서 시스템정보 업데이트",
    sensor_alive: "센서 헬스체크",
    sensor_patch_policy_status: "센서 정책 적용 상태 패치",
    sensor_check_token_validation: "센서 설치 토큰 유효성 검사",
    sensor_version_update: "센서 버전 업데이트",
    sensorpkg_download: "센서 패키지 다운로드",
    sensor_add_error_log: "센서 에러 로그 추가",
    command_add: "커맨드 추가",
    day: "일",
    hour: "시간",
    datastats_add_daily: "일일 수집 정보 추가",
    datastats_add_monthly: "월별 수집 정보 추가",
    file_status_patch: "파일 상태 패치",
    file_basic_add: "기본 파일 추가",
    file_basic_sensorinfo_add: "기본 파일 센서정보 추가",
  },
  file_log: {
    download_time: "다운로드 시간",
    file_name: "파일명",
    file_format: "파일 포맷",
    file_ext: "확장자",
    file_size: "파일 크기",
    log_msg: "내용",
    user_id: "계정 아이디",
    role_name: "역할",
    executable: "실행파일",
    non_executable: "비-실행파일",
  },
  msg: {
    sensor_package_name: "파일명",
    day: "일",
    sensor_policy_name: "센서제어 정책",
    group_name: "그룹명",
    range: "범위",
    long_term_absence_sensor_patch_count: "장기 미접속 센서수",
    collection_policy_name: "파일수집 정책",
    profile_name: "프로파일 정책",
    role_name: "역할",
    user_id: "유저명",
    base_package_platform: "플랫폼",
    base_package_version: "버전",
    command_info: "명령 정보",
    sensor_package_veresion: "버전",
    host_name: "호스트 이름",
    architecture: "아키텍처",
    package_version: "버전",
    package_platform: "플랫폼",
    format: "포맷",
    sha256: "SHA-256",
    status: "상태",
    command_count: "센서수",
    path: "경로",
    internal: "내부 오류",
    month: "월",
    token_id: "Token 아이디",
    add_first_admin: "최초 사용자 등록",
    add_user: "사용자 등록",
  },
};

export default audit;
