const system = {
  management: "시스템 관리",
  information: "시스템 정보",
  company_name: "회사 이름",
  domain: "도메인 정보",
  add: "추가",
  delete: "삭제",
  mail_server_settings: "메일 서버 설정",
  mail_server_ip: "메일 서버 IP 주소",
  port_number: "포트 번호",
  use_ssl: "SSL 사용 여부",
  not_used: "사용 안함",
  used: "사용함",
  smtp: "SMTP 인증",
  email: "이메일",
  password: "비밀번호",
  certification_test: "인증 테스트",
  email_address: "받는 사람 메일 주소",
  notification: "알림 설정",
  disk_usage: "디스크 공간",
  disk_usage_exceeded: "디스크 사용량 초과",
  license: "라이선스",
  license_expiration: "라이선스 만료",
  day_before: "일 전",
  synchronization: "시간 동기화 서버 설정",
  setup_sync: "직접 설정 및 동기화 선택",
  manual: "직접 설정",
  time_server: "시간 서버 동기화",
  file_storage: "로그 및 수집 파일 저장 설정",
  log_setting: "로그 설정",
  archiving: "로그 보관 설정",
  day_lapse: "일 경과",
  backup: "백업",
  backup_path: "백업경로",
  file_collection_setting: "파일 수집 서버 설정",
  server: "서버",
  region: "지역",
  access_key: "계정 ID",
  secret_access_key: "계정 비밀번호",
  server_address: "서버 주소",
  port: "포트",
  bucket: "버킷 이름",
  bucket_name: "파일 저장 경로",
  sensor_cycle: "센서 상태 판단 주기 설정",
  non_connection_cycle: "센서 장기미접속 판단 주기",
  day: "일",
  week: "주",
  days_weeks: "[ 최대 180일 또는 25주 이내 ]",
  save: "저장",
};

export default system;
