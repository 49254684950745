const alert = {
  dont_have_permission_to_access: "You don't have permission to access.",
  authentication_has_expired: "Your authentication has expired.",
  permission_info_does_not_exist: "Permission information does not exist.",
  // 공통
  internal_error_occurred: "An internal error({status}) occurred.",
  try_again_in_a_few_minutes: "Please try again in a few minutes.",
  check_and_try_again: "Please check and try again.",
  request_is_invalid: "The request is invalid.",
  saved_successfully: "It was saved successfully.",
  added_suceessfully: "It was added successfully.",
  deleted_successfully: "It was deleted successfully.",
  updated_successfully: "It was updated successfully.",
  unsaved_data_are_you_sure_you_want_to_leave:
    "You have unsaved data. Are you sure you want to leave?",
  invalid_email: "Invalid email address.",
  invalid_password_length: "The password is invalid in length.",
  invalid_password_format: "The password format is invalid.",
  not_support_clipboard_api: "Your browser does not support Clipboard API",
  name_already_exists: "Name already exists.",
  contact_admin: "Please contact your admin.",
  invalid_format: "Valid formats are IP, URL, and domain.",
  // 로그인(account)
  id_is_not_active: "ID is inactive.",
  id_does_not_exist: "ID does not exist.",
  name_does_not_exist: "Name does not exist.",
  email_already_exists: "Email already exists.",
  email_does_not_exist: "Email does not exist.",
  password_mismatch: "Password don't match.",
  new_password_equal: "The new password is the same as the old password.",
  not_allowed_ip: "IP not allowed to access.",
  invalid_query: "Query is invalid.",
  invalid_parameters: "Parameter is invalid.",
  account_locked: "Account is locked.",
  invalid_passcode: "OTP code is invalid.",
  passcode_required: "Requires an OTP code.",
  otp_secret_required: "Requires an OTP secret key.",
  // 로그인(token)
  token_not_found_in_header: "Request information(token) is invalid.",
  invalid_token: "Token is invalid.",
  expired_token: "Token has expired.",
  token_not_exist: "Token does not exist.",
  // 비밀번호 찾기
  email_sent_successfully: "Email was sent successfully.",
  // 등록 ID를 이용한 비밀번호 설정
  expired_register_id: "Register ID has expired.",
  register_id_does_not_exist: "Register ID does not exist.",
  // 비밀번호 재설정
  expired_reset_id: "Password reset ID has expired.",
  reset_id_does_not_exist: "Password reset ID does not exist.",
  try_forgot_password_again: 'Please try "Forgot your password" again.',
  // 프로파일>비밀번호 변경
  captcha_verified_successfully: "CAPTCHA character was verified successfully.",
  captcha_mismatch: "CAPTCHA characters do not match.",
  captcha_required: "Please verify CAPTCHA before proceeding.",
  //센서 현황>센서 목록
  cmd_collect_sensor_info: "Collect Sensors Info",
  cmd_update_sensor: "Update Sensors",
  cmd_uninstall_sensor: "Uninstall Sensors",
  send_cmd_successfully: "The command was sent successfully.",
  send_cmd_to_all: "Send the [{cmd}] command to all sensors?",
  send_cmd_to_sensor: "Send the [{cmd}] command to selected {count} sensors?",
  send_cmd_to_group: "Send the [{cmd}] command to the [{group}] group?",
  cant_be_used_to_all: "[{cmd}] command cannot be used for all sensors.",
  no_sensor: "A command cannot be sent to a group that does not have a sensor.",
  // 수집 현황>수집 목록
  no_data_to_download: "There is no data to download.",
  select_data_to_download: "Please select the data to download.",
  file_downloaded: "File downloaded successfully.",
  package_downloaded: "Package downloaded successfully.",
  select_data_to_copy: "Please select the data to be copied.",
  id_copied: "The file ID has been copied to the clipboard.",
  // 사용자 관리>역할 관리
  no_selected_roles: "No roles have been selected for deletion.",
  cant_delete_system_roles:
    "Can't delete role information that was created by the system.",
  in_use: "A role that is in use cannot be deleted.",
  // 사용자 관리>계정 관리
  cant_change_status_current_account:
    "Can't change the status of the current login account.",
  no_selected_users: "No accounts have been selected for deletion.",
  cant_delete_current_account: "Can't delete the current login account.",
  cant_delete_last_admin: "Can't delete the last admin account.",
  // 정책관리 > 프로파일 관리
  no_selected_sensor: "Must select a sensor policy name.",
  no_selected_collection: "Must select a collection policy name.",
  // 정책관리 > 센서제어 정책
  no_selected_days: "Select the day of the week",
  start_must_less_than_end: "Start time must be less than end time.",
  diff_between_start_end:
    "The number of minutes must be less than the difference between start time and end time.",
  enter_minimum_maximum_minute:
    "Must enter between a minimum of 1 minute and a maximum of 1440 minutes.",
  no_selected_policy: "No Policys have been selected for deletion.",
  must_enter_period: "Cycle must be entered.",
  must_enter_minute: "Range must be entered.",
  server_limit:
    "The maximum allowable registration number of management server is 4.",
  no_selected_server: "Must select the management server to be deleted.",
  must_select_version: "Must select a sensor version.",
  // 정책관리>파일수집 정책
  within_300_minutes: "Must enter within 300 minutes.",
  cant_delete_in_use_policy: "A policy that is in use cannot be deleted.",
  file_size_limit:
    "The collection file limit size must be entered between 1 and 50.",
  // 로그관리>관리콘솔 접속로그
  log_downloaded: "Log downloaded successfully.",
  // 설치 패키지 관리>기본셋업본 관리
  file_registered: "File has been registered.",
  no_selected_file: "No file selected.",
  must_select_file_first: "Must first select the files to be deleted.",
  cant_delete_last_file: "The last file cannot be deleted.",
  // 설치패키지 관리>설치패키지 관리
  no_selected_pkg: "No packages selected.",
  select_download_pkg_first: "Must first select which packages to download.",
  token_expiration_reset: "Token expiration date has been reset.",
  // 설치패키지관리>설치패키지빌더
  installation_pkg_created: "The installation package was created.",
  token_limit:
    "The token validity period must be set to 7 days or more and 365 days or less.",
  // 환경설정>시스템관리
  certification_required: "Certification tests are required.",
  account_verification_required: "Account verification is required.",
  email_verification_required: "Email verification is required.",
  must_enter_ip_server: "Must enter the mail server IP address.",
  email_authentication_pass: "Email authentication test passed.",
  must_enter_key: "Must enter the Access Key.",
  must_enter_secret_key: "Must enter the Secret Access Key.",
  must_enter_host: "Must enter the server address.",
  must_enter_port: "A port must be entered.",
  must_select_group: "Must select a region.",
  account_verification_passed: "Account verification test passed.",
  email_changed_rerun_test:
    "The information has been changed. Rerun email verification test.",
  server_changed_rerun_test:
    "The information has been changed. Rerun server verification test.",
  longterm_limit:
    "The sensor long-term disconnection judgment cycle must be entered within a maximum of 180 days or 25 weeks.",
  email_verification_failed: "Email verification failed.",
  account_verification_failed: "Account verification failed.",
  enter_disk_usage: "Must enter the Disk usage",
  enter_license_expire: "Must enter the License Expiration day",
  // 환경설정 > 그룹관리
  no_seleted_group: "No groups selected.",
  must_select_group_first: "Must select the group to be deleted first.",
  cant_delete_default_group: "Default Group cannot be deleted.",
  prefix_limit: "The PC host name must be entered within 5 digits.",
  ip_address_incorrect: "The entered IP address is incorrect.",
  duplicate_name: "Duplicate group names cannot be used.",
};

export default alert;
