const forgotpassword = {
  title: "Forgot your password",
  description:
    "This action requires mail verification.<br>Please enter your email address.<br>Check your inbox and follow the instructions provided.",
  email: "Email",
  email_placeholder: "Email Address",
  ok_btn: "OK",
};

export default forgotpassword;
