const loginRoutes = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunckName: "login" */ "../views/NrLoginView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(/* webpackChunckName: "admin" */ "../views/NrAdminView.vue"),
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () =>
      import(
        /* webpackChunckName: "forgot" */ "../views/NrForgotPasswordView.vue"
      ),
  },
  {
    path: "/otp/forgot",
    name: "otpforgot",
    component: () =>
      import(
        /* webpackChunckName: "otpforgot" */ "../views/NrForgotOtpView.vue"
      ),
  },
  {
    path: "/registration/:id",
    name: "registration",
    component: () =>
      import(
        /* webpackChunckName: "registration" */ "../views/NrRegistrationView.vue"
      ),
  },
  {
    path: "/invitation/:id",
    name: "invitation",
    component: () =>
      import(
        /* webpackChunckName: "setpassword" */ "../views/NrSetPasswordView.vue"
      ),
  },
  {
    path: "/password/reset/:id",
    name: "resetpassword",
    component: () =>
      import(
        /* webpackChunckName: "reset-password" */ "../views/NrResetPasswordView.vue"
      ),
  },
  {
    path: "/otp/reset/:id",
    name: "resetotp",
    component: () =>
      import(
        /* webpackChunckName: "reset-otp" */ "../views/NrResetOtpView.vue"
      ),
  },
];

export default loginRoutes;
