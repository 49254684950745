import { computed, reactive, ref } from "vue";
import { defineStore } from "pinia";
import { MenuItemType } from "@/types/types";

export const useMenuStore = defineStore("menu", () => {
  // 메뉴 정보
  const menus: MenuItemType[] = reactive([
    {
      id: "dashboard",
      title: "menu.dashboard",
      linkUrl: "/dashboard",
      className: "dashboard_menu1",
      iconId: "dashboard_menu_icon1",
      selected: true,
    },
    {
      id: "sensors",
      title: "menu.sensors",
      linkUrl: "/sensors",
      className: "dashboard_menu2",
      iconId: "dashboard_menu_icon2",
      subItems: [
        {
          id: "sensors",
          title: "menu.sensor_list",
          linkUrl: "/sensors",
        },
      ],
    },
    {
      id: "collections",
      title: "menu.collections",
      linkUrl: "/collections",
      className: "dashboard_menu3",
      iconId: "dashboard_menu_icon3",
      subItems: [
        {
          id: "collections",
          title: "menu.collection_list",
          linkUrl: "/collections",
        },
      ],
    },
    {
      id: "users",
      title: "menu.users",
      linkUrl: "/users/roles",
      className: "dashboard_menu4",
      iconId: "dashboard_menu_icon4",
      subItems: [
        {
          id: "users-roles",
          title: "menu.roles",
          linkUrl: "/users/roles",
        },
        {
          id: "users-users",
          title: "menu.accounts",
          linkUrl: "/users/users",
        },
      ],
    },
    {
      id: "policy",
      title: "menu.policy",
      linkUrl: "/policy/profiles",
      className: "dashboard_menu5",
      iconId: "dashboard_menu_icon5",
      subItems: [
        {
          id: "policy-profiles",
          title: "menu.policy_profile",
          linkUrl: "/policy/profiles",
        },
        {
          id: "policy-sensors",
          title: "menu.sensor_policy",
          linkUrl: "/policy/sensors",
        },
        {
          id: "policy-collections",
          title: "menu.file_collection_policy",
          linkUrl: "/policy/collections",
        },
      ],
    },
    {
      id: "audit",
      title: "menu.logs",
      linkUrl: "/audit/console-connection",
      className: "dashboard_menu6",
      iconId: "dashboard_menu_icon6",
      subItems: [
        {
          id: "audit-console-connection",
          title: "menu.console_connection_log",
          linkUrl: "/audit/console-connection",
        },
        {
          id: "audit-console",
          title: "menu.console_audit_log",
          linkUrl: "/audit/console",
        },
        {
          id: "audit-sensor",
          title: "menu.sensor_log",
          linkUrl: "/audit/sensor",
        },
        {
          id: "audit-filedownload",
          title: "menu.file_download_log",
          linkUrl: "/audit/filedownload",
        },
      ],
    },
    {
      id: "packages",
      title: "menu.packages",
      linkUrl: "/packages/bases",
      className: "dashboard_menu8",
      iconId: "dashboard_menu_icon8",
      subItems: [
        {
          id: "packages-bases",
          title: "menu.base_packages",
          linkUrl: "/packages/bases",
        },
        {
          id: "packages",
          title: "menu.install_packages",
          linkUrl: "/packages",
        },
        {
          id: "packages-builder",
          title: "menu.packages_builder",
          linkUrl: "/packages/builder",
        },
      ],
    },
    {
      id: "settings",
      title: "menu.settings",
      linkUrl: "/settings/system",
      className: "dashboard_menu7",
      iconId: "dashboard_menu_icon7",
      subItems: [
        {
          id: "settings-system",
          title: "menu.system_settings",
          linkUrl: "/settings/system",
        },
        {
          id: "settings-login",
          title: "menu.login_settings",
          linkUrl: "/settings/login",
        },
        {
          id: "settings-group",
          title: "menu.group_settings",
          linkUrl: "/settings/group",
        },
        {
          id: "settings-license",
          title: "menu.license_settings",
          linkUrl: "/settings/license",
        },
      ],
    },
  ]);

  // 왼쪽 메뉴(LeftNavigationBar) 수평 확장
  const leftNaviBarExpanded = ref(
    localStorage.getItem("left-navibar-expanded") == "true"
  );
  // 프로파일 메뉴 선택 여부
  const myProfile = ref(sessionStorage.getItem("myprofile") == "true");
  //선택된 메뉴 정보
  const selectedMenuId = ref(sessionStorage.getItem("selected-menu"));
  const selectedSubMenuId = ref(sessionStorage.getItem("selected-submenu"));

  const isLeftNaviBarExpanded = computed(() => {
    return leftNaviBarExpanded.value;
  });

  const isSelectedMyProfile = computed(() => {
    return myProfile.value;
  });

  const toggleLeftNaviBarExpand = () => {
    if (leftNaviBarExpanded.value) {
      menus.forEach((item) => {
        if (item.expanded) {
          item.expanded = false;
        }
      });
    } else {
      menus.forEach((item) => {
        if (item.selected) {
          item.expanded = true;
        }
      });
    }
    leftNaviBarExpanded.value = !leftNaviBarExpanded.value;
    localStorage.setItem(
      "left-navibar-expanded",
      leftNaviBarExpanded.value.toString()
    );
  };

  const selectMyProfile = () => {
    myProfile.value = true;
    sessionStorage.setItem("myprofile", myProfile.value.toString());
    selectMenu("", "");
  };

  const unselectMyProfile = () => {
    myProfile.value = false;
    sessionStorage.setItem("myprofile", myProfile.value.toString());
  };

  const selectMenu = (menuId: string, subMenuId: string) => {
    menus.forEach((item) => {
      if (item.id == menuId) {
        item.selected = true;
        item.subItems?.forEach((subItem) => {
          if (subItem.id == subMenuId) {
            subItem.selected = true;
          } else if (subItem.selected) {
            subItem.selected = false;
          }
        });
        unselectMyProfile();
      } else {
        if (item.selected) {
          item.selected = false;
          item.subItems?.forEach((subItem) => {
            if (subItem.selected) {
              subItem.selected = false;
            }
          });
        }
      }
    });

    sessionStorage.setItem("selected-menu", menuId);
    sessionStorage.setItem("selected-submenu", subMenuId);
  };

  //라우터 경로 정보를 이용해서 메뉴를 선택한다
  const selectMenuWithRoutePath = (routePath: string) => {
    let menuId = "";
    let subMenuId = "";
    menus.forEach((item) => {
      if (item.linkUrl === routePath) {
        menuId = item.id;
        subMenuId = "";
      }

      if (item.subItems && item.subItems.length > 0) {
        item.subItems?.forEach((subItem) => {
          if (subItem.linkUrl === routePath) {
            menuId = item.id;
            subMenuId = subItem.id;
          }
        });
      }
    });
    if (isLeftNaviBarExpanded.value) {
      expandMenuItem(menuId, true);
    }
    selectMenu(menuId, subMenuId);
  };

  const expandMenuItem = (menuId: string, expanded: boolean) => {
    menus.forEach((item) => {
      if (item.id == menuId) {
        item.expanded = expanded;
      }
    });
  };

  const collapseAllMenuItem = () => {
    menus.forEach((item) => {
      item.expanded = false;
    });
  };

  const clearSelecteMenu = () => {
    unselectMyProfile();
    selectMenu("", "");
    collapseAllMenuItem();
  };

  if (!selectedMenuId.value && !selectedSubMenuId.value && !myProfile.value) {
    selectMenu("dashboard", "");
  } else if (selectedMenuId.value && selectedSubMenuId.value) {
    selectMenu(selectedMenuId.value, selectedSubMenuId.value);
    if (leftNaviBarExpanded.value) {
      expandMenuItem(selectedMenuId.value, true);
    }
  } else if (selectedMenuId.value) {
    selectMenu(selectedMenuId.value, "");
  } else {
    selectMenu("", "");
  }

  return {
    menus,
    isLeftNaviBarExpanded,
    isSelectedMyProfile,
    selectMyProfile,
    toggleLeftNaviBarExpand,
    selectMenu,
    selectMenuWithRoutePath,
    clearSelecteMenu,
    expandMenuItem,
  };
});
