const system = {
  management: "System management",
  information: "System information",
  company_name: "Company name",
  domain: "Domain information",
  add: " Add",
  delete: "Delete",
  mail_server_settings: "Mail server settings",
  mail_server_ip: "Mail server IP address",
  port_number: "Port number",
  use_ssl: "Whether to use SSL",
  not_used: "Not used",
  used: "Used",
  smtp: "SMTP authentication",
  email: "Email",
  password: "Password",
  certification_test: "Certification test",
  email_address: "Recipient's email address",
  notification: "Notification settings",
  disk_usage: "Disk space",
  disk_usage_exceeded: "Disk usage exceeded",
  license: "License",
  license_expiration: "License Expiration",
  day_before: "day before",
  synchronization: "Time synchronization server settings",
  setup_sync: "Choose your own setup and sync",
  manual: "Manual setting",
  time_server: "Time server synchronization",
  file_storage: "Log and collection file storage settings",
  log_setting: "Log settings",
  archiving: "Log archiving settings",
  day_lapse: "day lapse",
  backup: "Back up",
  backup_path: "Backup path",
  file_collection_setting: "File Collection Setting",
  server: "Server",
  region: "Region",
  access_key: "Access Key",
  secret_access_key: "Secret Access Key",
  server_address: "Server Address",
  port: "Port",
  bucket: "Bucket Name",
  bucket_name: "Collection file storage path",
  sensor_cycle: "Set the sensor status judgment cycle",
  non_connection_cycle: "Sensor long-term non-connection judgment cycle",
  day: "day",
  week: "week",
  days_weeks: "[ Up to 180 days or within 25 weeks ]",
  save: "Save",
};

export default system;
