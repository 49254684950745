const license = {
  license_management: "License management",
  registration_license: "Registration license",
  change: "Change",
  delete: "Delete",
  validation: "Validation",
  product_name: "Product name",
  version: "Version",
  customer_name: "Customer name",
  license_remaining_days: "License remaining days",
  save: "Save",
};

export default license;
