const myprofile = {
  profile_title: "프로필 설정",
  user_title: "사용자 정보",
  email: "아이디",
  name: "이름",
  department: "소속부서",
  job_title: "직급(직무)",
  phone: "전화번호",
  mobile: "휴대번호",
  datetime_title: "날짜 및 시간",
  time_zone: "표준 시간대",
  us_hawaii: "(UTC-10:00) 하와이 (미국)",
  us_alaska: "(UTC-09:00) 알래스카 (미국)",
  us_pacific: "(UTC-08:00) 태평양 표준시 (미국)",
  us_mountain: "(UTC-07:00) 마운틴 표준시 (미국)",
  us_central: "(UTC-06:00) 중부 표준시 (미국)",
  us_eastern: "(UTC-05:00) 동부 표준시 (미국)",
  canada_atlantic: "(UTC-04:00) 대서양 표준시 (캐나다)",
  utc: "(UTC) 협정 세계시",
  europe_london: "(UTC+00:00) 런던",
  europe_paris: "(UTC+01:00) 파리",
  asia_shanghai: "(UTC+08:00) 베이징",
  asia_singapore: "(UTC+08:00) 싱가포르",
  asia_seoul: "(UTC+09:00) 서울",
  asia_tokyo: "(UTC+09:00) 도쿄",
  australia_sydney: "(UTC+10:00) 시드니",
  datetime_format: "날짜 형식",
  pass_api_title: "비밀번호 변경 및 API Key 설정",
  change_password_title: "비밀번호 변경",
  change_password: "비밀번호 변경",
  api_key_title: "API Key",
  api_key: "API Key",
  save_btn: "저장",
  change_password_btn: "비밀번호 변경",
  renew_btn: "갱신",
  user_modal: {
    title: "사용자 정보 변경",
    description: "사용자 정보를 변경하려면 비밀번호를 입력해 주세요.",
    password: "비밀번호",
    ok_btn: "확인",
    cancel_btn: "취소",
  },
  api_key_modal: {
    title: "API Key 갱신",
    description: "API Key 정보를 갱신하러면 비밀번호를 입력해 주세요.",
    sub_description:
      "API Key 갱신 시에는 기존에 적용된 API Key는 무효화 됩니다.",
    password: "비밀번호",
    ok_btn: "확인",
    cancel_btn: "취소",
  },
  //비밀번호 변경
  change_password_description:
    "안전한 비밀번호를 위하여 다음의 주의 사항을 확인해 주세요.",
  change_password_sub_description_1: "※ 다른 곳에서 사용한 적이 없는 비밀번호",
  change_password_sub_description_2:
    "※ 이전에 사용한 적이 없는 비밀번호가 안전합니다.",
  password: "현재 비밀번호",
  new_password: "새 비밀번호",
  confirm_new_password: "새 비밀번호 확인",
  password_precautions_title: "비밀번호 입력시 주의 사항",
  password_precautions:
    "비밀번호는 영어 대문자/소문자, 숫자, 특수문자를 모두 포함하여 9 ~ 32자리 사이로 입력해 주세요.\n(특수문자 {characters} 범위 내에서 선택)",
  captcha_desciription: "아래 이미지를 보이는 대로 입력해 주세요.",
  captcha_label: "자동입력 문자 방지",
  captcha_refresh_btn: "새로고침",
  captcha_ok_btn: "확인",
  change_password_save_btn: "저장",
};

export default myprofile;
